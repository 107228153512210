import shareIcon from "../../assets/svg/share.svg";
import signupIcon from "../../assets/svg/signup.svg";
import learningIcon from "../../assets/svg/learning.svg";
import quoteIcon from "../../assets/svg/quote.svg";
import "./Refer.css";

const Refer = () => {
  return (
    <div className="refer-page">
      <div className="header">
        <div className="container">
          <h1 className="display-3 text-center">ACV</h1>
        </div>
      </div>
      <div className="refer-body">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-4 px-3 py-5">
              <img
                src={quoteIcon}
                alt="quote-icon"
                className="mx-auto d-block"
              />
              <h2 className="text-white text-center">
                It's great for team building: we are a relatively new team. It
                helps us lay the foundation of future changes and design.
              </h2>
            </div>
            <div className="col-12 col-md-8">
              <div className="rounded bg-white m-4 p-4">
                <h3 className="text-center">Refer Your Colleagues</h3>
                <h5 className="text-center">
                  Amplify your impact and save US$260
                </h5>
                <div className="how-to-refer">
                  <h6>How to refer</h6>
                  <div className="d-flex gap-5">
                    <div className="refer-step">
                      <div className="icon-wrapper d-flex">
                        <img src={shareIcon} alt="share-icon" />
                      </div>
                      <p className="text-center">
                        Share personal referral code via email or personal link
                        below
                      </p>
                    </div>
                    <div className="refer-step">
                      <div className="icon-wrapper d-flex">
                        <img src={signupIcon} alt="signup-icon" />
                      </div>
                      <p className="text-center">
                        Your colleague signs up for a Kellogg program using your
                        code
                      </p>
                    </div>
                    <div className="refer-step">
                      <div className="icon-wrapper d-flex">
                        <img src={learningIcon} alt="learning-icon" />
                      </div>
                      <p className="text-center">
                        Start learning together. Receive
                        <span class="strong">US$260</span>
                        off program fees for each colleague that signs up with
                        your code
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Refer;
