import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import config from "config";

import CommanHeader from "components/Header";
import "./SuccessPayment.css";

const SuccessPayment = () => {
  const { t } = useTranslation();

  const [message, setMessage] = useState([]);
  const [paymentImage, setPaymentImage] = useState();
  const [isCancel, setIsCancel] = useState(false);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    if (query.get("success")) {
      setIsCancel(false);
      const msg = [t("successpayment")[0], t("successpayment")[1]];
      setMessage(msg);
      setPaymentImage(
        `https://${config.STORAGE}.blob.core.windows.net/react-images/Successful purchase1.png`
      );
    } else {
      setIsCancel(true);
      const msg = [t("canclePayment")[0], t("canclePayment")[1]];
      setPaymentImage(
        `https://${config.STORAGE}.blob.core.windows.net/react-images/PaymentCancelSVG.svg`
      );
      setMessage(msg);
    }
  }, []);

  return (
    <>
      <div className="success-payement_container Landing">
        <div className="succss-payment-header">
          <CommanHeader />
        </div>
        <div className="success-payment-wrapper">
          <div
            className={
              isCancel
                ? `succuss-payment-main cancel-payment`
                : `succuss-payment-main`
            }
          >
            <img src={paymentImage} alt="succuss-payment" />
            {window.location.search === "?success=true&subscription=false" ? (
              <p>
                Your credit purchase has been confirmed. You will receive a
                confirmation email. For the order with the invoice, please check
                your email for more details.
              </p>
            ) : (
              <p>
                {isCancel ? (
                  window.location.search ===
                  "?canceled=true&subscription=false" ? (
                    <p>
                      You have canceled the process, no amount has been charged
                      to your account. If you want to purchase more credits, go
                      to the "Purchase Credits" page to initiate the purchase
                      process again.
                    </p>
                  ) : (
                    t("canclePayment")[0]
                  )
                ) : (
                  t("successpayment")[0]
                )}
                <p>
                  {isCancel
                    ? window.location.search ===
                        "?canceled=true&subscription=true" &&
                      t("canclePayment")[1]
                    : window.location.search ===
                        "?success=true&subscription=true" &&
                      t("successpayment")[1]}
                </p>
              </p>
            )}
            <div className="succuss-payment-button">
              <Link to="/">
                <button className="btn btn_go_to_acv">
                  {t("canclePayment")[2]}
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SuccessPayment;
