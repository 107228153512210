import React from "react";
import { useTranslation } from "react-i18next";

import CommanHeader from "components/Header";
import Footer from "components/Footer";
import Feature from "components/Feature";

import "./School.css";

const School = (props, { insideTeam }) => {
  const { t } = useTranslation();
  const data = t("SchoolPage");

  return (
    <>
      <CommanHeader />
      <div className="container">
        <div className="row row-grid justify-content-around align-items-center py-8">
          {data?.map((info, key) => (
            <Feature info={info} key={key} />
          ))}
        </div>
      </div>
      <Footer classname="school" />
    </>
  );
};

export default School;
