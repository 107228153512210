import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import * as Icon from "react-feather";
import config from "config";
import { Alert } from "@fluentui/react-northstar";

const PlanHistory = (props) => {
  const [planHistory, setPlanHistory] = useState([]);
  const [expirePlan, setExpirePlan] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (props.user?.dbData?.dayRemaining < 0) {
      setExpirePlan(true);
    }
    if (props.user) {
      axios
        .post(`${config.ENDPOINT}/api/payment/download-invoice`, {
          email: props.user.username ? props.user.username : props.user?.mail,
        })
        .then((res) => {
          setPlanHistory(res.data.result);
        })
        .catch((err) => {
          console.log(err.message);
          setError(true);
        });
    }
  }, []);
  const planHistoryModal = (props) => (
    <div
      className="modal fade pr-0 bd-example-modal-lg"
      id="planHistory"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="planHistoryLabel"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-scrollable modal-lg"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="planHistoryLabel">
              Account History
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {/* <div className="row align-items-center text-left"> */}
            {planHistory.map((plan, index) => (
              <div
                className="d-flex justify-content-between text-left py-3"
                key={plan.invoiceId}
              >
                <div className="media align-items-center">
                  <span className="avatar bg-info text-white rounded-circle mr-3">
                    <Icon.ShoppingBag size={"22"} />
                  </span>
                  <div className="media-body">
                    <h5 className="mb-0">
                      {props.user?.dbData?.Subscription} Account
                    </h5>
                    <p className="text-muted lh-150 text-sm mb-0">
                      {expirePlan || index !== 0
                        ? "Your plan has been expired"
                        : "Your plan will expire on" +
                          moment(props.user?.dbData?.ExpirationDate).format(
                            "MMMM d, YYYY"
                          )}
                      .
                    </p>
                  </div>
                </div>
                <div className="col-auto flex-md-fill mt-2 mt-sm-3 mt-lg-0 text-sm-right pl-0 pr-0">
                  <a
                    className="btn btn-sm btn-neutral rounded-pill"
                    onClick={() => window.open(plan.pdf, "_blank")}
                  >
                    Download
                  </a>
                </div>
              </div>
            ))}
            {/* </div> */}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            <button type="button" className="btn btn-primary">
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <>
      {props.children}
      {planHistoryModal(props)}
      <Alert
        attached="top"
        content=""
        danger
        header="Contact support for assistance with this invoice."
        visible={error}
        style={{ marginTop: "10px" }}
      />
    </>
  );
};

export default PlanHistory;
