import React from "react";
import * as Icon from "react-feather";
import CtaButton from "components/CtaButton";

import "./Cta.css";
import { useTranslation } from "react-i18next";

const Cta = () => {
  const { t } = useTranslation();

  return (
    <div className="Cta">
      <div className="row justify-content-center mx-0">
        <div className="col-9">
          <h1 className="display-4 text-uppercase">{t("landing").cta[0]}</h1>
          <div className="mt-5">
            <CtaButton
              name={t("landing").cta[1]}
              link="prices"
              icon={<Icon.ArrowRight className="ms-2" />}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cta;
