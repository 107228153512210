import React from "react";
import * as Icon from "react-feather";

export const FeatureList = ({ item }) => {
  return (
    <li className="py-2">
      <div className="d-flex align-items-center">
        <div>
          <div className="icon icon-shape icon-primary icon-sm rounded-circle mr-3">
            <Icon.Check />
          </div>
        </div>
        <div>
          <span className="h6 mb-0">{item}</span>
        </div>
      </div>
    </li>
  );
};
