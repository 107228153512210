import React from "react";
import CompareSwiper from "components/CompareSwiper";
import MainHeading from "components/MainHeading";

import "./hero.css";

const Hero = (props) => {
  return (
    <div className="hero space-x2">
      <div className="container">
        <MainHeading
          data={{ heading: props?.data?.heading, info: props?.data?.info }}
        />
        <CompareSwiper />
      </div>
    </div>
  );
};

export default Hero;
