import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { withRouter, useHistory, Router } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../CheckoutForm ";
import { Button, Header } from "@fluentui/react-northstar";
import * as Icon from "react-feather";

import "./Subscribe.css";

const Subscribe = ({ location }) => {
  const stripePromise = loadStripe(
    "pk_test_51IxOtvEHnlV5VdcYTWQb09I029v93TPI3ObuYSpy99ObJjSh565ktdlbLP5uxsYvK3U3vGMTEKmVOXr2Q19l41F8002qj8x4Jd"
  );
  const history = useHistory();

  // Get the lookup key for the price from the previous page redirect.
  const [clientSecret] = useState(location.state?.state.clientSecret);

  if (!clientSecret) {
    history.push("/register");
  }

  return (
    <div className="Subscribe">
      <div className="stp">
        <Button
          icon={<Icon.ArrowLeft />}
          text
          content="Back"
          className="back-btn"
          onClick={() => history.push("/register")}
        />
        <br />
        <Elements stripe={stripePromise}>
          <Header as="h2" align="center" color="black" className="h2">
            Enter Payment Details
          </Header>
          <CheckoutForm clientSecret={clientSecret} />
        </Elements>
      </div>
    </div>
  );
};

export default withRouter(Subscribe);
