import React, { useContext, useEffect } from "react";
import ProfileHeader from "components/ProfileHeader";
import Dashboard from "components/Dashboard";
import Footer from "components/Footer";
import { UserContext } from "context/UserContext";
import { useHistory } from "react-router-dom";

const Profile = (props) => {
  const history = useHistory();
  const { user, nonSubscriber } = useContext(UserContext);

  useEffect(() => {
    if (!user || nonSubscriber) {
      history.push("/non-subscriber");
    }
  }, [nonSubscriber, user]);

  return (
    <>
      <ProfileHeader />
      <Dashboard {...props} />
      <Footer />
    </>
  );
};

export default Profile;
