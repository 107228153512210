import React, { useContext, useEffect, useState } from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import "./Storage.css";
import { Loader } from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";

function Storage() {
  const { t } = useTranslation();
  const [storageDetails, setStorageDetails] = useState([]);

  const data = [
    {
      planId: "1",
      planName: "Basic",
      storage_student: "20 GB",
      storage_teacher: "50 GB",
      storage_externalSource: "1",
      status: "active",
      price: "5",
    },
    {
      planId: "2",
      planName: "Standard",
      storage_student: "20 GB",
      storage_teacher: "50 GB",
      storage_externalSource: "2",
      status: "active",
      price: "40",
    },
    {
      planId: "3",
      planName: "Premium",
      storage_student: "20 GB",
      storage_teacher: "50 GB",
      storage_externalSource: "5",
      status: "active",
      price: "100",
    },
  ];

  useEffect(() => {
    setStorageDetails(data);
  }, []);

  return (
    <div className="btsp">
      <Header />
      <section
        className={`slice slice-lg bg-section-secondary`}
        style={{ paddingTop: "6rem" }}
      >
        <div className="container">
          <div className="row mb-5 justify-content-center text-center">
            <div className="col-lg-8 col-md-10">
              <h2 className="mt-5">{t("landing").storage[0]}</h2>
              <div className="mt-2">
                <p className="lead lh-180 text-muted">
                  {t("landing").storage[1]}
                </p>
              </div>
            </div>
          </div>

          <div className="row justify-content-center px-4">
            {storageDetails?.length ? (
              storageDetails?.map((storageDetail, key) => {
                return (
                  storageDetail?.status === "active" && (
                    <div
                      className="col-xl-4 col-md-6"
                      key={"Plan-key" + storageDetail.planId}
                    >
                      <div
                        className="card card-pricing text-center px-3 shadow hover-scale-110"
                        style={{
                          backgroundColor:
                            storageDetail.planName === "Standard" && "#f5feff",
                        }}
                      >
                        {storageDetail.planName === "Standard" && (
                          <div className="wrap">
                            <span className="ribbon6">Popular</span>
                          </div>
                        )}
                        <div className="card-header py-5 border-0 delimiter-bottom">
                          <div className="h1 text-center mb-0 min-width-content">
                            $
                            <span className="price font-weight-bolder">
                              {storageDetail.price}
                            </span>
                            <span className="h5"></span>
                          </div>
                          <span className="h6 text-muted">
                            {storageDetail.planName}
                          </span>
                          <br />
                        </div>
                        <div className="card-body">
                          <ul className="list-unstyled text-sm mb-4">
                            <li>{`Student: ${storageDetail.storage_student}`}</li>
                            <li>{`Teacher: ${storageDetail.storage_teacher}`}</li>
                            <li>{`External Source: ${storageDetail.storage_externalSource}`}</li>
                          </ul>
                          <a
                            className="btn btn-sm btn-info hover-translate-y-n3 hover-shadow-lg mb-3 mx-1"
                            data-toggle="modal"
                            data-target="#addModel"
                            style={{
                              backgroundColor: "#D33139",
                              borderColor: "#D33139",
                            }}
                          >
                            <span className="btn-inner--text">
                              {t("landing").price[3]}
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  )
                );
              })
            ) : (
              <Loader
                size="medium"
                style={{ paddingTop: "10vh", paddingBottom: "10vh" }}
              />
            )}
          </div>
        </div>
      </section>
      <Footer className="credit" />
    </div>
  );
}

export default Storage;
