import React from "react";
import MainHeading from "components/MainHeading";
import ContentScroller from "components/ContentScroller";
import Cta from "components/Cta";
import Header from "components/Header";
import Footer from "components/Footer";
import "./Diagram.css";
import { useTranslation } from "react-i18next";

const Diagram = (props) => {
  const { t } = useTranslation();
  const data = t("Products")[9];
  return (
    <div className="btsp">
      <Header />
      <div className="space-x2">
        <div className="container">
          <MainHeading data={{ heading: data?.heading, info: data?.info }} />
          <div className="space-t">
            <div className="bg-white p-2 tog-container">
              <div className="toggler-navs d-flex align-content-center">
                <label
                  aria-current="page"
                  title="ACV"
                  className="d-flex align-self-center"
                >
                  <img
                    draggable="false"
                    width="48"
                    height="48"
                    src="/logo192.png"
                    alt="ACV logo"
                    className="rounded-5"
                  />
                </label>
                <h5 className="fs-5 ps-5 pe-5 report-header">Diagram</h5>
              </div>
              <img
                src="/assets/images/diagram6.png"
                width="100%"
                alt="Booking Img"
                className="toggler-img"
              />
            </div>
          </div>
        </div>
      </div>
      <ContentScroller data={data} />
      <Cta />
      <Footer classname="attendance" />
    </div>
  );
};

export default Diagram;
