import React from "react";
import * as Icon from "react-feather";
import parse from "html-react-parser";

const Question = ({ que }) => {
  const IconTag = Icon[que[0]];
  return (
    <div className="col-md-6 col-lg-4 mb-4">
      <h6 className="mb-3">
        {IconTag && <IconTag size="16" className="mr-1" />} {que[1]}
      </h6>
      <p>{parse(que[2])}</p>
    </div>
  );
};

export default Question;
