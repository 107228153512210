import React, { useContext } from "react";
import * as Icon from "react-feather";
import { Link } from "react-router-dom";

import IconLogo from "assets/img/icon.png";
import { UserContext } from "context/UserContext";

function ProfileHeader() {
  const { user } = useContext(UserContext);

  const contactUsref = React.useRef();

  const openContactUsModal = () => {
    const query = new URLSearchParams(window.location.search);
    if (query.get("contact")) {
      contactUsref.current.click();
    }
  };

  React.useEffect(() => {
    openContactUsModal();
  }, []);

  return (
    <div>
      <header className="select-none">
        <nav
          className="navbar navbar-main navbar-expand-lg navbar-light border-bottom border-light"
          id="navbar-main"
        >
          <div className="container">
            <Link to="/" className="navbar-brand">
              <img
                alt="ACV - Tangible IT"
                src={IconLogo}
                className="navbar-logo"
                id="navbar-logo"
              />
            </Link>
            <div className="d-none d-sm-block">
              <h5 className="mb-0">Welcome back, {user?.name}</h5>
            </div>
            <div className="order-lg-4 ml-lg-3">
              <a href="#modal-profile" role="button" data-toggle="modal">
                <span className="avatar rounded-circle">
                  {user?.image ? (
                    <img
                      alt="Image placeholder"
                      src={user.image + user.SASToken}
                    />
                  ) : (
                    <div className="icon icon-shape icon-md bg-light shadow-light text-white overflow-hidden rounded-pill">
                      <Icon.User color="black" />
                    </div>
                  )}
                </span>
              </a>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
}

export default ProfileHeader;
