import React from "react";
import Hero from "components/Hero";
import ContentScroller from "components/ContentScroller";
import Cta from "components/Cta";
import wbLoader from "./whiteboard";
import Header from "components/Header";
import Footer from "components/Footer";

import "./Whiteboard.css";
import { useTranslation } from "react-i18next";

const Whiteboard = () => {
  const { t } = useTranslation();
  const data = t("Products")[2];
  return (
    <div className="whiteboard btsp" onLoad={wbLoader}>
      <Header />
      <Hero data={{ heading: data?.heading, info: data?.info }} />

      <div id="force" className="d-none"></div>
      <div id="touches" className="d-none"></div>
      <canvas style={{ width: "100%", height: "100%" }}>
        Sorry, your browser is too old for experiencing whiteboard.
      </canvas>

      <ContentScroller data={data} />
      <Cta />
      <Footer classname="whiteboard" />
    </div>
  );
};

export default Whiteboard;
