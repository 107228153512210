import React from "react";
import { FeatureList } from "./FeatureList";

const Feature = ({ info }) => {
  return (
    <div className="row row-grid justify-content-around align-items-center my-6">
      <div
        className={`order-lg-${
          info.contentRight ? 2 : 1
        } col-lg-6 order-lg-2 ml-lg-auto p${
          info.contentRight ? "l" : "r"
        }-lg-6`}
      >
        <h5 className="h2 mt-4">{info.title}</h5>
        <p className="lead lh-190 my-4 text-muted">{info.description}</p>
        <ul className="list-unstyled">
          {info.bullets?.map((item, key) => (
            <FeatureList item={item} key={key} />
          ))}
        </ul>
      </div>
      <div className={`col-lg-6 order-lg-${info.contentRight ? 1 : 2}`}>
        <div
          className="position-relative zindex-100 rounded overflow-hidden"
          style={{ textAlign: "center" }}
        >
          <img
            alt="ACV - Tangible IT"
            src={info.imgUrl}
            className="img-fluid fImg"
          />
        </div>
      </div>
    </div>
  );
};

export default Feature;
