import React from "react";
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from "react-compare-slider";
import "./CompareSlider.css";

const CompareSwiper = () => {
  return (
    <div className="Swiper rounded-5 space-t">
      <ReactCompareSlider
        itemOne={
          <ReactCompareSliderImage
            src="/assets/images/light-whiteboard.png"
            alt="Light Whiteboard"
          />
        }
        itemTwo={
          <ReactCompareSliderImage
            src="/assets/images/dark-whiteboard.png"
            alt="Dark Whiteboard"
            className="dark-image"
          />
        }
        position={46}
        handle={
          <div className="dragHandle">
            <img
              src={require("assets/img/svg/draggable.svg").default}
              className="dragger"
              alt="dragger"
            />
          </div>
        }
        className="z-i-999"
      />
    </div>
  );
};

export default CompareSwiper;
