import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { Dialog } from "@fluentui/react-northstar";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { getCustomer } from "./PaymentData";
import axios from "axios";
import config from "config";

const CheckoutForm = ({ clientSecret }) => {
  const history = useHistory();

  const stripe = useStripe();
  const elements = useElements();
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [msg, setMsg] = useState("Something went wrong! Please try again.");

  const handleSubmit = async (event) => {
    // Block native form submission.

    const customer = getCustomer();
    if (customer && Object.keys(customer).length !== 0) {
      event.preventDefault();
    } else {

      history.push("/register");
    }

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    // Use your card Element with other Stripe.js APIs
    let res = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: cardElement,
        billing_details: {
          name: customer.name,
        },
      },
      receipt_email: customer.email,
    });

    const language =
      window.localStorage.getItem("i18nextLng")?.substring(0, 2)?.toLowerCase() ||
      "en";

    const postData = {
      email: customer.email,
      name: customer.name,
      subscriptionId: customer.subscriptionId,
      subscripation_type: customer.subscripation_type,
      School: customer.school,
      SchoolAddress: customer.address,
      ContactPhone: customer.phone,
      Post: customer.job,
      language
    };

    if (res.error) {
      setOpen(true);
      setMsg(res.error.message);
      console.log("[error]", res.error);
      setSuccess(true);
      setOpen(true);
      setMsg(
        `Payment succeeded - your transaction id is ${res.paymentIntent.id}. Check your mailbox, we have invited you to access ACV app.`
      );
      axios
        .post(`${config.ENDPOINT}/api/payment/create-admin`, postData)
        .then((result) => {
     
          // history.push("/account");
        })
        .catch((err) => {
          setMsg(err.message);
        });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Dialog
        open={open}
        onOpen={() => setOpen(true)}
        // onCancel={() => setOpen(false)}
        onConfirm={() => {
          if (success) {
            history.push("");
          } else {
            setOpen(false);
          }
        }}
        confirmButton="Ok"
        content={msg}
        header="Action confirmation"
      />
      <CardElement
        options={{
          style: {
            base: {
              fontSize: "16px",
              color: "#424770",
              "::placeholder": {
                color: "#424770",
              },
            },
            invalid: {
              color: "#9e2146",
            },
          },
        }}
      />
      <button className="s-btn" type="submit" disabled={!stripe}>
        Pay
      </button>
    </form>
  );
};

export default CheckoutForm;
