import React, { useContext, useEffect, useState } from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import "./credit.css";
import { Loader } from "@fluentui/react-northstar";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";
import axios from "axios";
import config from "config";
import { UserContext } from "context/UserContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function PurchaseCredit() {
  const { t } = useTranslation();
  const history = useHistory();
  const [credits, setCredits] = useState([]);
  const { user, accessToken } = useContext(UserContext);
  const [loaderId, setLoaderId] = useState("");

  const getCreditPlansInfo = () => {
    axios
      .get(`${config.ENDPOINT}/api/credit/get-credit-packages`, {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      })
      .then((res) => {
        const response = res.data.result;
        const data = response.map((data) => {
          if (data.planId === "NwWXA9IbdF") {
            return { ...data, order: 0 };
          } else if (data.planId === "H0viuV3Usj") {
            return { ...data, order: 1 };
          } else if (data.planId === "JATcVPaCrK") {
            return { ...data, order: 2 };
          }
        });
        const sortData = [...data].sort((a, b) => a.order - b.order);
        setCredits(sortData);
        return res.data.result;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const createCheckoutSession = async (id) => {
    setLoaderId(id);
    const url = config.ENDPOINT;
    const postData = {
      paymentType: "credit_purchase",
      TeacherEmailID: user.mail,
      planId: id,
    };
    const data = await axios
      .post(
        `${url}/api/payment/create-checkout-session${user.slug}`,
        postData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
        }
      )
      .then((res) => {
        window.location.href = res.data.session.url;
        setLoaderId("");
        return res;
      })
      .catch((err) => {
        const { status } = err.response;
        if (status === 409) {
          setIsError(true);
          setLoaderId("");
        }
      });
  };
  useEffect(() => {
    getCreditPlansInfo();
  }, []);

  return (
    <div className="btsp">
      <Header />
      <section
        className={`slice slice-lg bg-section-secondary`}
        style={{ paddingTop: "6rem" }}
      >
        <div className="container">
          <div className="row mb-5 justify-content-center text-center">
            <div className="col-lg-8 col-md-10">
              <h2 className="mt-5">{t("landing").purchaseCredit[0]}</h2>
              <div className="mt-2">
                <p className="lead lh-180 text-muted">
                  {t("landing").purchaseCredit[1]}
                </p>
              </div>
            </div>
          </div>

          <div className="row justify-content-center px-4">
            {credits?.length ? (
              credits?.map((credit, key) => {
                const data = JSON.parse(credit.services);

                return (
                  credit?.status === "active" && (
                    <div
                      className="col-xl-4 col-md-6"
                      key={"Plan-key" + credit.planId}
                    >
                      <div
                        className="card card-pricing text-center px-3 shadow hover-scale-110"
                        style={{
                          backgroundColor:
                            credit.planName === "Standard" && "#f5feff",
                        }}
                      >
                        {credit.planName === "Standard" && (
                          <div className="wrap">
                            <span className="ribbon6">Popular</span>
                          </div>
                        )}
                        <div className="card-header py-5 border-0 delimiter-bottom">
                          <div className="h1 text-center mb-0 min-width-content">
                            $
                            <span className="price font-weight-bolder">
                              {credit.price}
                            </span>
                            <span className="h5"></span>
                          </div>
                          <span className="h6 text-muted">
                            {credit.planName}
                          </span>
                          <br />
                        </div>
                        <div className="card-body">
                          <ul className="list-unstyled text-sm mb-4">
                            <li
                              style={{ padding: "4px" }}
                            >{`${credit.credits} credits`}</li>
                            {data.map((service) => {
                              return (
                                <li style={{ padding: "4px" }}>
                                  {parse(service)}
                                </li>
                              );
                            })}
                          </ul>
                          <a
                            className="btn btn-sm btn-info hover-translate-y-n3 hover-shadow-lg mb-3 mx-1"
                            data-toggle="modal"
                            data-target="#addModel"
                            style={{
                              background:
                                loaderId === credit.planId
                                  ? "#c4c4c4"
                                  : "#d33139",
                              border: "#d33139",
                              pointerEvents:
                                loaderId === credit.planId && "none",
                            }}
                            onClick={() => {
                              accessToken
                                ? createCheckoutSession(credit.planId)
                                : history.push("/auth", {
                                    state: { from: "/credits" },
                                  });
                            }}
                          >
                            {loaderId === credit.planId ? (
                              <div
                                style={{
                                  gap: "5px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  class="spinner-border text-secondary"
                                  role="status"
                                  style={{ width: "1rem", height: "1rem" }}
                                >
                                  <span className="sr-only">Loader...</span>
                                </div>
                                <span className="btn-inner--text">
                                  {t("landing").price[3]}
                                </span>
                              </div>
                            ) : (
                              <span className="btn-inner--text">
                                {t("landing").price[3]}
                              </span>
                            )}
                          </a>
                        </div>
                      </div>
                    </div>
                  )
                );
              })
            ) : (
              <Loader
                size="medium"
                style={{ paddingTop: "10vh", paddingBottom: "10vh" }}
              />
            )}
          </div>
        </div>
      </section>
      <Footer className="credit" />
    </div>
  );
}

export default PurchaseCredit;
