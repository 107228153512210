import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "config";
import Footer from "components/Footer";

import "./carousel";

import { Link, useLocation } from "react-router-dom";

import { useTranslation } from "react-i18next";

import CommanHeader from "components/Header";

import * as Icon from "react-feather";
import "assets/css/styles.scss";
import "./index.css";
import Cta from "components/Cta";

import logo from "assets/img/small-icon.png";
import dashboard from "assets/img/dashboard.gif";

const Landing = (props) => {
  const { t } = useTranslation();
  const [msgData, setMsgData] = useState(null);
  const [tab, setTab] = useState("tab-1");
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const location = useLocation();

  const [currentContent, setCurrentContent] = useState({});

  let newContentId = 1;

  React.useEffect(() => {
    setCurrentContent(Contents[0]);

    const intervalId = setInterval(() => {
      if (newContentId < Contents.length) {
        newContentId++;
        setCurrentContent(Contents.find((c) => c.id === newContentId));
      } else if (newContentId === Contents.length) {
        newContentId = 1;
        setCurrentContent(Contents[0]);
      }
    }, 8000);

    if (location.pathname === "/contact") {
      document.querySelector("#btn-contact").click();
    }

    if (location.pathname === "/demo") {
      document.querySelector("#btn-demo").click();
    }

    return () => clearInterval(intervalId); // Cleanup the interval on component unmount
  }, []);

  const showMessage = () => {
    return (
      <div
        className={
          msgData.type === "Success"
            ? "alert alert-success fixed-bottom alert-dismissible fade show"
            : "alert alert-danger fixed-bottom alert-dismissible fade show"
        }
        role="alert"
        style={{
          zIndex: "9999",
          width: "500px",
          position: "fixed",
          left: "auto",
          bottom: "0px",
        }}
      >
        {msgData.message}
        <button
          type="button"
          className="close"
          onClick={() =>
            document
              .getElementsByClassName("alert")[0]
              ?.classList.remove("show")
          }
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    );
  };

  const addCouponHandler = (e) => {
    e.preventDefault();
    axios
      .post(`${config.ENDPOINT}/api/admin/post-coupon-user`, {
        Email: document.getElementById("school-email").value,
        SchoolName: document.getElementById("school-name").value,
        UserName: document.getElementById("user-name").value,
        SchoolAddress: document.getElementById("school-address").value,
        OfficePhone: document.getElementById("school-phone").value,
      })
      .then((res) => {
        setMsgData(
          {
            message:
              "We have saved your information successfully, you will get email invitation once we approved.",
            type: res.data.status,
          } || null
        );
        setTimeout(() => {
          setMsgData(null);
        }, 10000);
        document.getElementById("close-modal").click();
        document.getElementById("school-email").value = "";
        document.getElementById("school-name").value = "";
        document.getElementById("user-name").value = "";
        document.getElementById("school-address").value = "";
        document.getElementById("school-phone").value = "";
      })
      .catch((err) => {
        console.log(err);
        setMsgData(
          { message: err.response.data.message, type: err.response.status } ||
            null
        );
      });
  };

  const Products = [
    {
      image: "/assets/images/att1.png",
      name: t("landing").home.attendance.title,
      eventBgColor: "#E5BEEC",
    },
    {
      image: "/assets/images/ass1.png",
      name: t("landing").home.assignment.title,
      eventBgColor: "#90B77D",
    },
    {
      image: "/assets/images/evt1.png",
      name: t("landing").home.event.title,
      eventBgColor: "#DAF5FF",
    },
    {
      image: "/assets/images/dash1.png",
      name: t("landing").home.dashboard.title,
      eventBgColor: "#BCCEF8",
    },
    {
      image: "/assets/images/whiteboard1.png",
      name: t("landing").home.whiteboard.title,
      eventBgColor: "#EAEAEA",
    },
    {
      image: "/assets/images/att2.png",
      name: t("landing").home.attendance.title,
      eventBgColor: "#FFD4D4",
    },
    ,
    {
      image: "/assets/images/ass.png",
      name: t("landing").home.assignment.title,
      eventBgColor: "#FFF8E1",
    },
    {
      image: "/assets/images/events.png",
      name: t("landing").home.event.title,
      eventBgColor: "#E8C4C4",
    },
    {
      image: "/assets/images/dash2.png",
      name: t("landing").home.dashboard.title,
      eventBgColor: "#B6E388",
    },
  ];

  const Contents = [
    {
      id: 1,
      name: t("landing").content.admin.name,
      content: t("landing").content.admin.points,
      image: "/assets/images/admin.jpg",
    },
    {
      id: 2,
      name: t("landing").content.teacher.name,
      content: t("landing").content.teacher.points,
      image: "/assets/images/teacher.jpg",
    },
    {
      id: 3,
      name: t("landing").content.student.name,
      content: t("landing").content.student.points,
      image: "/assets/images/student.jpg",
    },
  ];

  const BlackSectionContent = t("landing").blackSection;
  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="Landing bg-white">
      {msgData ? showMessage() : null}
      <div
        className="modal fade pr-0"
        id="addModel"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="addCoupon"
        aria-hidden="true"
        style={{ backgroundColor: "#0000005e" }}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content text-left">
            <form onSubmit={(e) => addCouponHandler(e)} method="POST">
              <div className="modal-body">
                <div className="form-group">
                  <h5 className="modal-title pt-1 pb-4" id="addCoupon">
                    {t("landing").hero[3]}
                  </h5>
                  <label htmlFor="school-name">{t("landing").demo[0][0]}</label>
                  <input
                    type="text"
                    className="form-control"
                    id="school-name"
                    placeholder={t("landing").demo[0][1]}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="school-address">
                    {t("landing").demo[1][0]}
                  </label>
                  <textarea
                    className="form-control"
                    id="school-address"
                    placeholder={t("landing").demo[1][1]}
                    required
                  ></textarea>
                </div>
                <div className="form-group">
                  <label htmlFor="school-phone">
                    {t("landing").demo[2][0]}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="school-phone"
                    placeholder={t("landing").demo[2][1]}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="user-name">{t("landing").demo[3][0]}</label>
                  <input
                    type="text"
                    className="form-control"
                    id="user-name"
                    placeholder={t("landing").demo[3][1]}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="school-email">
                    {t("landing").demo[4][0]}
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="school-email"
                    placeholder={t("landing").demo[4][1]}
                    required
                  />
                </div>
                <button
                  type="button"
                  className="btn btn-secondary py-2 mt-2 mb-3"
                  data-dismiss="modal"
                  id="close-modal"
                >
                  {t("landing").demo[5][0]}
                </button>
                <button
                  type="submit"
                  className="btn btn-primary py-2 mt-2 mb-3"
                >
                  {t("landing").demo[5][1]}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <CommanHeader />
      {/* ---------------- Start Top Section -------------- */}
      <section className="slice slice-lg top-section">
        <div className="container">
          <div className="row row-grid align-items-center mx-0 d-lg-flex d-md-block">
            <div className="col-12 col-md-12 col-lg-6 order-2 py-3 pt-0 py-lg-0 top-section-carousel">
              <div class="carousel-wrapper">
                <div class="carousel">
                  {Products.map((product, key) => (
                    <div class="carousel__item" key={key}>
                      <div
                        class="carousel__item-head"
                        style={{ backgroundColor: product.eventBgColor }}
                      >
                        <p>{product.name}</p>
                      </div>
                      <div class="carousel__item-body">
                        <img
                          src={product.image}
                          class="img-fluid carousel__item-img"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-6 order-1 pr-md-5 mt-lg-0 top-section-header">
              <h1 className="display-3 text-center text-md-left mb-3">
                {t("landing").hero[0] + " "}
                <strong className="text-brand">{t("landing").hero[1]}</strong>
              </h1>
              <p className="lead text-center text-md-left text-muted">
                {t("landing").hero[2]}
              </p>
              <div className="text-center text-md-left mt-md-5 mt-sm-2">
                <a
                  className="btn btn-primary btn-icon mr-2"
                  data-toggle="modal"
                  data-target="#addModel"
                  id="btn-demo"
                >
                  <span className="btn-inner--text">
                    {t("landing").hero[3]}
                  </span>
                  <span className="btn-inner--icon">
                    <Icon.ArrowRight size="18" />{" "}
                  </span>
                </a>
                <a
                  href={config.DOC_URL}
                  target="_blank"
                  className="btn btn-neutral btn-icon  d-lg-inline-block ml-0 documentation-btn"
                >
                  {t("landing").hero[4]}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className="text-center text-md-center mt-md-5 mt-sm-2"
          style={{
            fontSize: "28px",
            fontWeight: "600",
            marginTop: "4rem !important",
          }}
        >
          <span>{t("landing").hero[5]}</span>
          <span style={{ color: "#d3313a" }}>{t("landing").hero[6]}</span>
          <span>{t("landing").hero[7]}</span>
          <span style={{ color: "#d3313a" }}>{t("landing").hero[8]}</span>
          <span>{t("landing").hero[9]}</span>
        </div>
      </section>
      {/* -------------- End Top Section ------------------ */}
      <section>
        <div class="row mx-0">
          <div className="col-sm-12 col-lg-3 p-0 bg-dark-black">
            <div class="section2-wrapper">
              <div class="logo-container">
                <img src={logo} alt="" className="logo" />
                <span className="logo-name">{t("landing").home.header}</span>
              </div>
              <div class="btn-container ">
                <div className="wrapper ">
                  <h3 class="text-white big-font">{t("landing").home.title}</h3>
                  <div class="btn-wrapper">
                    <ul
                      class="opt-btns "
                      role="tablist"
                      aria-labelledby="tablist-1"
                    >
                      <div class="row">
                        <div class="col">
                          {" "}
                          <li
                            class={`opt-btn1 opt-btn ${
                              tab === "tab-1" ? "active" : ""
                            }`}
                            id="tab-1"
                            role="tab"
                            aria-selected="true"
                            aria-controls="tabpanel-1"
                            tabindex="0"
                            aria-label="Zoom One"
                            style={{ transform: "matrix(1, 0, 0, 1, 0, 0);" }}
                            onClick={() => setTab("tab-1")}
                          >
                            <span className="opt-pre-text">
                              {t("landing").home.main}&nbsp;
                            </span>{" "}
                            <span className="opt-text">
                              {t("landing").home.attendance.title}
                            </span>
                          </li>
                        </div>
                        <div class="col">
                          {" "}
                          <li
                            class={`opt-btn2 opt-btn ${
                              tab === "tab-2" ? "active" : ""
                            }`}
                            id="tab-2"
                            role="tab"
                            aria-selected="false"
                            aria-controls="tabpanel-2"
                            tabindex="-1"
                            aria-label="Zoom Spaces"
                            style={{ transform: "matrix(1, 0, 0, 1, 0, 0);" }}
                            onClick={() => setTab("tab-2")}
                          >
                            <span className="opt-pre-text">
                              {t("landing").home.main}&nbsp;
                            </span>{" "}
                            <span className="opt-text">
                              {t("landing").home.assignment.title}
                            </span>
                          </li>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          {" "}
                          <li
                            class={`opt-btn3 opt-btn ${
                              tab === "tab-3" ? "active" : ""
                            }`}
                            id="tab-3"
                            role="tab"
                            aria-selected="false"
                            aria-controls="tabpanel-3"
                            tabindex="-1"
                            aria-label="Zoom Events"
                            style={{ transform: "matrix(1, 0, 0, 1, 0, 0);" }}
                            onClick={() => setTab("tab-3")}
                          >
                            <span className="opt-pre-text">
                              {t("landing").home.main}&nbsp;
                            </span>{" "}
                            <span className="opt-text">
                              {t("landing").home.event.title}
                            </span>
                          </li>
                        </div>
                        <div class="col">
                          {" "}
                          <li
                            class={`opt-btn4 opt-btn ${
                              tab === "tab-4" ? "active" : ""
                            }`}
                            id="tab-4"
                            role="tab"
                            aria-selected="false"
                            aria-controls="tabpanel-4"
                            tabindex="-1"
                            aria-label="Zoom Contact Center"
                            style={{ transform: "matrix(1, 0, 0, 1, 0, 0);" }}
                            onClick={() => setTab("tab-4")}
                          >
                            <span className="opt-pre-text">
                              {t("landing").home.main}&nbsp;
                            </span>{" "}
                            <span className="opt-text">
                              {t("landing").home.dashboard.title}
                            </span>
                          </li>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          {" "}
                          <li
                            class={`opt-btn5 opt-btn ${
                              tab === "tab-5" ? "active" : ""
                            }`}
                            id="tab-5"
                            role="tab"
                            aria-selected="false"
                            aria-controls="tabpanel-5"
                            tabindex="-1"
                            aria-label="Zoom Developers"
                            style={{ transform: "matrix(1, 0, 0, 1, 0, 0);" }}
                            onClick={() => setTab("tab-5")}
                          >
                            <span className="opt-pre-text">
                              {t("landing").home.main}&nbsp;
                            </span>{" "}
                            <span className="opt-text">
                              {t("landing").home.whiteboard.title}
                            </span>
                          </li>
                        </div>
                        <div class="col">
                          {" "}
                          <li
                            class={`opt-btn5 opt-btn ${
                              tab === "tab-6" ? "active" : ""
                            }`}
                            id="tab-6"
                            role="tab"
                            aria-selected="false"
                            aria-controls="tabpanel-6"
                            tabindex="-1"
                            aria-label="Zoom Developers"
                            style={{ transform: "matrix(1, 0, 0, 1, 0, 0);" }}
                            onClick={() => setTab("tab-6")}
                          >
                            <span className="opt-pre-text">
                              {t("landing").home.main}&nbsp;
                            </span>{" "}
                            <span className="opt-text">
                              {t("landing").home.feedback.title}
                            </span>
                          </li>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          {" "}
                          <li
                            class={`opt-btn5 opt-btn ${
                              tab === "tab-8" ? "active" : ""
                            }`}
                            id="tab-8"
                            role="tab"
                            aria-selected="false"
                            aria-controls="tabpanel-8"
                            tabindex="-1"
                            aria-label="Zoom Developers"
                            style={{ transform: "matrix(1, 0, 0, 1, 0, 0);" }}
                            onClick={() => setTab("tab-8")}
                          >
                            <span
                              className="opt-text"
                              style={{ marginLeft: "0px" }}
                            >
                              {t("landing").home.booking.title}
                            </span>
                          </li>
                        </div>
                        <div class="col">
                          {" "}
                          <li
                            class={`opt-btn5 opt-btn ${
                              tab === "tab-9" ? "active" : ""
                            }`}
                            id="tab-9"
                            role="tab"
                            aria-selected="false"
                            aria-controls="tabpanel-9"
                            tabindex="-1"
                            aria-label="Zoom Developers"
                            style={{ transform: "matrix(1, 0, 0, 1, 0, 0);" }}
                            onClick={() => setTab("tab-9")}
                          >
                            <span
                              className="opt-text"
                              style={{ marginLeft: "0px" }}
                            >
                              {t("landing").home.diagram.title}
                            </span>
                          </li>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          {" "}
                          <li
                            class={`opt-btn5 opt-btn ${
                              tab === "tab-10" ? "active" : ""
                            }`}
                            id="tab-10"
                            role="tab"
                            aria-selected="false"
                            aria-controls="tabpanel-10"
                            tabindex="-1"
                            aria-label="Zoom Developers"
                            style={{ transform: "matrix(1, 0, 0, 1, 0, 0);" }}
                            onClick={() => setTab("tab-10")}
                          >
                            <span
                              className="opt-text"
                              style={{ marginLeft: "0px" }}
                            >
                              {t("landing").home.spinwheel.title}
                            </span>
                          </li>
                        </div>
                        <div class="col">
                          {" "}
                          <li
                            class={`opt-btn5 opt-btn ${
                              tab === "tab-11" ? "active" : ""
                            }`}
                            id="tab-11"
                            role="tab"
                            aria-selected="false"
                            aria-controls="tabpanel-11"
                            tabindex="-1"
                            aria-label="Zoom Developers"
                            style={{ transform: "matrix(1, 0, 0, 1, 0, 0);" }}
                            onClick={() => setTab("tab-11")}
                          >
                            <span
                              className="opt-text"
                              style={{ marginLeft: "0px" }}
                            >
                              {t("landing").home.videoAvatars.title}
                            </span>
                          </li>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          {" "}
                          <li
                            class={`opt-btn5 opt-btn ${
                              tab === "tab-7" ? "active" : ""
                            }`}
                            id="tab-7"
                            role="tab"
                            aria-selected="false"
                            aria-controls="tabpanel-7"
                            tabindex="-1"
                            aria-label="Zoom Developers"
                            style={{ transform: "matrix(1, 0, 0, 1, 0, 0);" }}
                            onClick={() => setTab("tab-7")}
                          >
                            <span
                              className="opt-text"
                              style={{ marginLeft: "0px" }}
                            >
                              {t("landing").home.magicbar.title}
                            </span>
                          </li>
                        </div>
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-lg-9 tab-section pb-4 pb-md-0">
            {tab === "tab-1" && (
              <div
                id="tabpanel-1"
                className="row tabpanel animate__animated animate__fadeInRight mx-0"
              >
                <div className="text-center pt-3 pt-lg-0 img p-3 col-sm-12 col-lg-6">
                  <img
                    src="/assets/images/att.png"
                    className="img-fluid animate__animated animate__bounceInRight img-width"
                  />
                </div>
                <div className="m-0 col-sm-12 col-lg-6 pt-3 p-sm-5 animate__animated animate__zoomIn animate__fadeInRight pb-5">
                  <h4 className="display-6">
                    {t("landing").home.attendance.title}
                  </h4>
                  <p className="text-dark">
                    {t("landing").home.attendance.detail}
                  </p>
                  <ul className="points pt-4 list-unstyled">
                    {t("landing").home.attendance.points.map((data, key) => (
                      <li className="fs-6 py-1" key={key}>
                        <div className="d-flex align-items-center">
                          <div>
                            <div className="icon icon-shape icon-primary icon-sm rounded-circle mr-3">
                              <Icon.Check />
                            </div>
                          </div>
                          <div>
                            <span className="h6 mb-0">{data}</span>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>

                  <Link
                    to="/attendance"
                    className="btn btn-outline-danger btn-sm animate__animated animate__backInRight mt-4"
                  >
                    {t("landing").home.attendance.btnName}
                  </Link>
                </div>
              </div>
            )}
            {tab === "tab-2" && (
              <div
                id="tabpanel-2"
                className="row tabpanel animate__animated animate__fadeInRight   mx-0"
              >
                <div className="text-center img p-3 pt-3 pt-lg-0 col-sm-12 col-lg-6">
                  <img
                    src="/assets/images/ass.png"
                    className="img-fluid animate__animated animate__bounceInRight img-width"
                  />
                </div>

                <div className="m-0 col-sm-12 col-lg-6 p-sm-5 animate__animated animate__zoomIn animate__fadeInRight pb-5">
                  <h4 className="display-6">
                    {t("landing").home.assignment.title}
                  </h4>
                  <p className="text-dark">
                    {t("landing").home.assignment.detail}
                  </p>
                  <ul className="points list-unstyled pt-2">
                    {t("landing").home.assignment.points.map((data, key) => (
                      <li className="fs-6 py-1" key={key}>
                        <div className="d-flex align-items-center">
                          <div>
                            <div className="icon icon-shape icon-primary icon-sm rounded-circle mr-3">
                              <Icon.Check />
                            </div>
                          </div>
                          <div>
                            <span className="h6 mb-0">{data}</span>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                  <Link
                    to="/assignment"
                    className="btn btn-outline-danger btn-sm animate__animated animate__backInRight mt-2"
                  >
                    {t("landing").home.assignment.btnName}
                  </Link>
                </div>
              </div>
            )}
            {tab === "tab-3" && (
              <div
                id="tabpanel-3"
                className="row tabpanel animate__animated animate__fadeInRight  mx-0 "
              >
                <div className="text-center img p-3 pt-3 pt-lg-0 col-sm-12 col-lg-6">
                  <img
                    src="/assets/images/event.png"
                    className="img-fluid   animate__animated animate__bounceInRight img-width"
                  />
                </div>
                <div className="m-0 col-sm-12 col-lg-6 p-sm-5 animate__animated animate__zoomIn animate__fadeInRight pb-5">
                  <h4 className="display-6">{t("landing").home.event.title}</h4>
                  <ul className="points pt-4 list-unstyled">
                    {t("landing").home.event.points.map((data, key) => (
                      <li className="fs-6 py-1" key={key}>
                        <div className="d-flex align-items-center">
                          <div>
                            <div className="icon icon-shape icon-primary icon-sm rounded-circle mr-3">
                              <Icon.Check />
                            </div>
                          </div>
                          <div>
                            <span className="h6 mb-0">{data}</span>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                  <Link
                    to="/events"
                    className="btn btn-outline-danger btn-sm animate__animated animate__backInRight mt-4"
                  >
                    {t("landing").home.event.btnName}
                  </Link>
                </div>
              </div>
            )}
            {tab === "tab-4" && (
              <div
                id="tabpanel-4"
                className="row tabpanel animate__animated animate__fadeInRight mx-0 "
              >
                <div className="text-center img p-3 pt-3 pt-lg-0 col-sm-12 col-lg-6 ">
                  <img
                    src="/assets/images/dash.png"
                    className="img-fluid  animate__animated animate__bounceInRight img-width"
                  />
                  <img
                    src={dashboard}
                    className="gif  animate__animated animate__backInRight d-none d-lg-block"
                    height={150}
                    width={200}
                  />
                </div>
                <div className="m-0 col-sm-12 col-lg-6 p-sm-5 animate__animated animate__zoomIn animate__fadeInRight pb-2">
                  <h4 className="display-6">
                    {t("landing").home.dashboard.title}
                  </h4>
                  <p className="text-dark">
                    {t("landing").home.dashboard.description}
                  </p>
                  <ul className="points pt-2 list-unstyled">
                    {t("landing").home.dashboard.points.map((data, key) => (
                      <li className="fs-6 py-1" key={key}>
                        <div className="d-flex align-items-center">
                          <div>
                            <div className="icon icon-shape icon-primary icon-sm rounded-circle mr-3">
                              <Icon.Check />
                            </div>
                          </div>
                          <div>
                            <span className="h6 mb-0">{data}</span>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                  <Link
                    to="/dashboard"
                    className="btn btn-outline-danger btn-sm animate__animated animate__backInRight mt-2"
                  >
                    {t("landing").home.dashboard.btnName}
                  </Link>
                </div>
              </div>
            )}
            {tab === "tab-5" && (
              <div
                id="tabpanel-5"
                className="row tabpanel animate__animated animate__fadeInRight mx-0"
              >
                <div className="text-center img p-3 pt-3 pt-lg-0 col-sm-12 col-lg-6">
                  <img
                    src="/assets/images/whiteboard.png"
                    className="img-fluid  animate__animated animate__bounceInRight img-width"
                  />
                </div>
                <div className="m-0 col-sm-12 col-lg-6 p-sm-5 animate__animated animate__zoomIn animate__fadeInRight pb-5 pl-md-5">
                  <h4 className="display-6">
                    {t("landing").home.whiteboard.title}
                  </h4>
                  <p className="text-dark">
                    {t("landing").home.whiteboard.detail}
                  </p>
                  <ul className="points pt-4 list-unstyled">
                    {t("landing").home.whiteboard.points.map((data, key) => (
                      <li className="fs-6 py-1" key={key}>
                        <div className="d-flex align-items-center">
                          <div>
                            <div className="icon icon-shape icon-primary icon-sm rounded-circle mr-3">
                              <Icon.Check />
                            </div>
                          </div>
                          <div>
                            <span className="h6 mb-0">{data}</span>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                  <Link
                    to="/whiteboard"
                    className="btn btn-outline-danger btn-sm animate__animated animate__backInRight mt-4"
                  >
                    {t("landing").home.whiteboard.btnName}
                  </Link>
                </div>
              </div>
            )}
            {tab === "tab-6" && (
              <div
                id="tabpanel-6"
                className="row tabpanel animate__animated animate__fadeInRight mx-0"
              >
                <div className="text-center img p-3 pt-3 pt-lg-0 col-sm-12 col-lg-6">
                  <img
                    src="/assets/images/feedback.png"
                    className="img-fluid  animate__animated animate__bounceInRight img-width"
                  />
                </div>
                <div className="m-0 col-sm-12 col-lg-6 p-sm-5 animate__animated animate__zoomIn animate__fadeInRight pb-5 pl-md-5">
                  <h4 className="display-6">
                    {t("landing").home.feedback.title}
                  </h4>
                  <p className="text-dark">
                    {t("landing").home.feedback.detail}
                  </p>
                  <ul className="points pt-4 list-unstyled">
                    {t("landing").home.feedback.points.map((data, key) => (
                      <li className="fs-6 py-1" key={key}>
                        <div className="d-flex align-items-center">
                          <div>
                            <div className="icon icon-shape icon-primary icon-sm rounded-circle mr-3">
                              <Icon.Check />
                            </div>
                          </div>
                          <div>
                            <span className="h6 mb-0">{data}</span>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                  <Link
                    to="/feedback"
                    className="btn btn-outline-danger btn-sm animate__animated animate__backInRight mt-4"
                  >
                    {t("landing").home.feedback.btnName}
                  </Link>
                </div>
              </div>
            )}
            {tab === "tab-8" && (
              <div
                id="tabpanel-8"
                className="row tabpanel animate__animated animate__fadeInRight mx-0"
              >
                <div className="text-center img p-3 pt-3 pt-lg-0 col-sm-12 col-lg-6">
                  <img
                    src="/assets/images/booking2.png"
                    className="img-fluid  animate__animated animate__bounceInRight img-width"
                  />
                </div>
                <div className="m-0 col-sm-12 col-lg-6 p-sm-5 animate__animated animate__zoomIn animate__fadeInRight pb-5 pl-md-5">
                  <h4 className="display-6">
                    {t("landing").home.booking.title}
                    {t("landing").home.booking.heading}
                  </h4>
                  <p className="text-dark">
                    {t("landing").home.booking.detail}
                  </p>
                  <ul className="points pt-4 list-unstyled">
                    {t("landing").home.booking.points.map((data, key) => (
                      <li className="fs-6 py-1" key={key}>
                        <div className="d-flex align-items-center">
                          <div>
                            <div className="icon icon-shape icon-primary icon-sm rounded-circle mr-3">
                              <Icon.Check />
                            </div>
                          </div>
                          <div>
                            <span className="h6 mb-0">{data}</span>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                  <Link
                    to="/bookings"
                    className="btn btn-outline-danger btn-sm animate__animated animate__backInRight mt-4"
                  >
                    {t("landing").home.booking.btnName}
                  </Link>
                </div>
              </div>
            )}
            {tab === "tab-9" && (
              <div
                id="tabpanel-9"
                className="row tabpanel animate__animated animate__fadeInRight mx-0"
              >
                <div className="text-center img p-3 pt-3 pt-lg-0 col-sm-12 col-lg-6">
                  <img
                    src="/assets/images/diagram6.png"
                    className="img-fluid  animate__animated animate__bounceInRight img-width"
                  />
                </div>
                <div className="m-0 col-sm-12 col-lg-6 p-sm-5 animate__animated animate__zoomIn animate__fadeInRight pb-5 pl-md-5">
                  <h4 className="display-6">
                    {t("landing").home.diagram.title}
                    {t("landing").home.diagram.heading}
                  </h4>
                  <p className="text-dark">
                    {t("landing").home.diagram.detail}
                  </p>
                  <ul className="points pt-4 list-unstyled">
                    {t("landing").home.diagram.points.map((data, key) => (
                      <li className="fs-6 py-1" key={key}>
                        <div className="d-flex align-items-center">
                          <div>
                            <div className="icon icon-shape icon-primary icon-sm rounded-circle mr-3">
                              <Icon.Check />
                            </div>
                          </div>
                          <div>
                            <span className="h6 mb-0">{data}</span>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                  <Link
                    to="/diagram"
                    className="btn btn-outline-danger btn-sm animate__animated animate__backInRight mt-4"
                  >
                    {t("landing").home.diagram.btnName}
                  </Link>
                </div>
              </div>
            )}
            {tab === "tab-10" && (
              <div
                id="tabpanel-10"
                className="row tabpanel animate__animated animate__fadeInRight mx-0"
              >
                <div className="text-center img p-3 pt-3 pt-lg-0 col-sm-12 col-lg-6">
                  <img
                    src="/assets/images/spin1.png"
                    className="img-fluid  animate__animated animate__bounceInRight img-width"
                  />
                </div>
                <div className="m-0 col-sm-12 col-lg-6 p-sm-5 animate__animated animate__zoomIn animate__fadeInRight pb-5 pl-md-5">
                  <h4 className="display-6">
                    {t("landing").home.spinwheel.title}
                    {t("landing").home.spinwheel.heading}
                  </h4>
                  <p className="text-dark">
                    {t("landing").home.spinwheel.detail}
                  </p>
                  <ul className="points pt-4 list-unstyled">
                    {t("landing").home.spinwheel.points.map((data, key) => (
                      <li className="fs-6 py-1" key={key}>
                        <div className="d-flex align-items-center">
                          <div>
                            <div className="icon icon-shape icon-primary icon-sm rounded-circle mr-3">
                              <Icon.Check />
                            </div>
                          </div>
                          <div>
                            <span className="h6 mb-0">{data}</span>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                  <Link
                    to="/spin-wheel"
                    className="btn btn-outline-danger btn-sm animate__animated animate__backInRight mt-4"
                  >
                    {t("landing").home.spinwheel.btnName}
                  </Link>
                </div>
              </div>
            )}
            {tab === "tab-11" && (
              <div
                id="tabpanel-11"
                className="row tabpanel animate__animated animate__fadeInRight mx-0"
              >
                <div className="text-center img p-3 pt-3 pt-lg-0 col-sm-12 col-lg-6">
                  <img
                    src="/assets/images/gan1.png"
                    className="img-fluid  animate__animated animate__bounceInRight img-width"
                  />
                </div>
                <div className="m-0 col-sm-12 col-lg-6 p-sm-5 animate__animated animate__zoomIn animate__fadeInRight pb-5 pl-md-5">
                  <h4 className="display-6">
                    {t("landing").home.videoAvatars.title}
                    {t("landing").home.videoAvatars.heading}
                  </h4>
                  <p className="text-dark">
                    {t("landing").home.videoAvatars.detail}
                  </p>
                  <ul className="points pt-4 list-unstyled">
                    {t("landing").home.videoAvatars.points.map((data, key) => (
                      <li className="fs-6 py-1" key={key}>
                        <div className="d-flex align-items-center">
                          <div>
                            <div className="icon icon-shape icon-primary icon-sm rounded-circle mr-3">
                              <Icon.Check />
                            </div>
                          </div>
                          <div>
                            <span className="h6 mb-0">{data}</span>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                  <Link
                    to="/videoAvatars"
                    className="btn btn-outline-danger btn-sm animate__animated animate__backInRight mt-4"
                  >
                    {t("landing").home.videoAvatars.btnName}
                  </Link>
                </div>
              </div>
            )}
            {tab === "tab-7" && (
              <div
                id="tabpanel-7"
                className="row tabpanel animate__animated animate__fadeInRight mx-0"
              >
                <div className="text-center img p-3 pt-3 pt-lg-0 col-sm-12 col-lg-6">
                  <img
                    src="/assets/images/magicbar.png"
                    className="img-fluid  animate__animated animate__bounceInRight img-width"
                  />
                </div>
                <div className="m-0 col-sm-12 col-lg-6 p-sm-5 animate__animated animate__zoomIn animate__fadeInRight pb-5 pl-md-5">
                  <h4 className="display-6">
                    {t("landing").home.magicbar.title}
                    {t("landing").home.magicbar.heading}
                  </h4>
                  <p className="text-dark">
                    {t("landing").home.magicbar.detail}
                  </p>
                  <ul className="points pt-4 list-unstyled">
                    {t("landing").home.magicbar.points.map((data, key) => (
                      <li className="fs-6 py-1" key={key}>
                        <div className="d-flex align-items-center">
                          <div>
                            <div className="icon icon-shape icon-primary icon-sm rounded-circle mr-3">
                              <Icon.Check />
                            </div>
                          </div>
                          <div>
                            <span className="h6 mb-0">{data}</span>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                  <Link
                    to="/ai"
                    className="btn btn-outline-danger btn-sm animate__animated animate__backInRight mt-4"
                  >
                    {t("landing").home.magicbar.btnName}
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
      <section className="mb-sm-2 mb-md-5" id="Features" />
      <section className="slice">
        <div className="container">
          <div className="row justify-content-lg-end">
            <div className="col-lg-5 text-lg-right">
              <h6 className="display-4 text-danger mb-0">
                {t("landing").home.main}
              </h6>
              <p className="lh-180 mb-3 text-muted fs-1 mb-0">
                {t("landing").initial.text}
              </p>
            </div>
            <div className="col-lg-6">
              <h1 className="lh-130 fw-700">{t("landing").initial.title}</h1>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-4">
              <div className="card border-0 bg-soft-grey shadow-lg">
                <div className="card-body pb-5">
                  <div className="pt-0 pb-3 pt-lg-4 pb-lg-5">
                    <img
                      src="/assets/images/evt2.png"
                      className="img-fluid img-center"
                      style={{ height: "200px" }}
                      alt="Illustration"
                    />
                  </div>
                  <h5 className="h4 lh-130 text-dark mb-3">
                    {t("landing").card.event.title}
                  </h5>
                  <p className="text-dark opacity-6 mb-0 fs-1">
                    {t("landing").card.event.detail}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card border-0 bg-soft-grey mt-lg-5 shadow-lg">
                <div className="card-body pb-5">
                  <div className="pt-0 pb-3 pt-lg-4 pb-lg-5">
                    <img
                      src="/assets/images/ass.png"
                      className="img-fluid img-center"
                      style={{ height: "200px" }}
                      alt="Illustration"
                    />
                  </div>
                  <h5 className="h4 lh-130 text-dark mb-3">
                    {t("landing").card.task.title}
                  </h5>
                  <p className="text-dark opacity-6 mb-0 fs-1">
                    {t("landing").card.task.detail}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card border-0 bg-soft-grey mt-lg-7 shadow-lg">
                <div className="card-body pb-5">
                  <div className="pt-0 pb-3 pt-lg-4 pb-lg-5">
                    <img
                      src="/assets/images/dash2.png"
                      className="img-fluid img-center"
                      style={{ height: "200px" }}
                      alt="Illustration"
                    />
                  </div>
                  <h5 className="h4 lh-130 text-dark mb-3">
                    {t("landing").feature[4][0]}
                  </h5>
                  <p className="text-dark opacity-6 mb-0 fs-1">
                    {t("landing").card.dash.detail}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="slice slice-lg pt-0">
        <div className="container animation">
          <div
            className="row row-grid justify-content-around align-items-center"
            style={{ minHeight: 620 }}
          >
            <div className="col-lg-6 order-lg-2 ml-lg-auto pl-lg-6">
              <h5 className="h2 mt-4 text-capitalize d-flex" style={{ alignItems: "baseline" }}>
                Main features for{" "}
                <span className="title">{currentContent?.name}</span>
              </h5>
              <p className="lead lh-190  text-muted">
                {t("landing").teacher[1]}
              </p>
              <ul className="list-unstyled">
                {currentContent?.content &&
                  currentContent?.content.map((data, index) => (
                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div>
                          <div className="icon icon-shape icon-primary icon-sm rounded-circle mr-3">
                            <Icon.Check />
                          </div>
                        </div>
                        <div>
                          <span className="h6 mb-0">{data}</span>
                        </div>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
            <div className="col-lg-6 order-lg-1 section-cnt mt-0">
              <div className="position-relative zindex-100 p-3">
                <img
                  alt="ACV - Tangible IT"
                  src={currentContent?.image}
                  className="img-fluid"
                  style={{ borderRadius: 8 }}
                />
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section className="slice slice-lg bg-section-dark pt-5 pt-lg-8">
        <section class="black-section">
          <div class="container">
            <div class="row">
              <div class="col-lg-6  d-lg-block">
                <ol
                  class={`carousel-indicators tabs ${
                    screenWidth < "992"
                      ? "d-flex justify-content-evenly gap-3"
                      : ""
                  }`}
                >
                  {BlackSectionContent.map((data, index) => (
                    <li
                      data-target="#carouselExampleIndicators"
                      data-slide-to={`${index}`}
                      class={`${index === 0 ? "active" : ""}`}
                      style={{ flexBasis: screenWidth < "992" ? "33%" : "" }}
                    >
                      <figure
                        style={{
                          backgroundColor: "#585A96",
                          borderRadius: "50%",
                          aspectRatio: screenWidth < "992" ? "1 / 1" : "",
                        }}
                      >
                        <img
                          src={data.image.url}
                          class="img-fluid"
                          alt=""
                          style={{
                            borderRadius: "50%",
                            objectFit: "cover",
                            height:
                              screenWidth > "992" ? data.image.height : "100%",
                            width:
                              screenWidth > "992" ? data.image.width : "100%",
                          }}
                        />
                      </figure>
                    </li>
                  ))}
                </ol>
              </div>
              <div class="col-lg-6 d-flex justify-content-center align-items-center">
                <div
                  id="carouselExampleIndicators"
                  data-interval="false"
                  class="carousel slide"
                  data-ride="carousel"
                >
                  <h3>{t("landing").director[0]}</h3>
                  <div class="carousel-inner">
                    {BlackSectionContent.map((data, index) => (
                      <>
                        <div
                          class={`carousel-item ${index === 0 ? "active" : ""}`}
                        >
                          <h2 className="text-white pt-3">{data.heading}</h2>
                          <div class="quote-wrapper">
                            <p>{data.description}</p>
                            <div className="d-flex" style={{ gap: 10 }}>
                              <h3>{data.title}</h3>
                              <span
                                className={`badge ${data.tipsBg} badge-pill`}
                                style={{
                                  height: "fit-content",
                                  marginTop: "38px",
                                }}
                              >
                                {data.tips}
                              </span>
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                  <ol class="carousel-indicators indicators">
                    {BlackSectionContent.map((_, index) => (
                      <li
                        data-target="#carouselExampleIndicators"
                        data-slide-to={`${index}`}
                      ></li>
                    ))}
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      <section className="slice slice-lg bg-ex-light">
        <div className="container">
          <div className="row row-grid justify-content-around align-items-center">
            <div className="col-lg-6 order-lg-1 ml-lg-auto pr-lg-6">
              <h5 className="h2 mt-4 text-capitalize">{`${
                t("Products")[0]?.heading[1]
              }`}</h5>
              <p className="lead lh-190 my-4 text-muted">
                {t("Products")[0]?.tagline}
              </p>
              <ul className="list-unstyled">
                {t("Products")[0]?.content[0]?.map((product, key) => {
                  return (
                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div>
                          <div className="icon icon-shape icon-primary icon-sm rounded-circle mr-3">
                            <Icon.Check />
                          </div>
                        </div>
                        <div>
                          <span className="h6 mb-0">{product.heading}</span>
                        </div>
                      </div>
                    </li>
                  );
                })}
                <li className="py-2">
                  <div className="d-flex align-items-center">
                    <div className="text-center text-md-left mt-4">
                      <a
                        className="btn bg-secondary text-dark btn-icon mr-2"
                        href="/attendance"
                      >
                        <span className="btn-inner--text">
                          {t("landing").button[0]}
                        </span>
                        <span className="btn-inner--icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <line x1="5" y1="12" x2="19" y2="12"></line>
                            <polyline points="12 5 19 12 12 19"></polyline>
                          </svg>{" "}
                        </span>
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-lg-6 order-lg-2 shadow-lg-xl rounded-xl overflow-hidden bg-white box-content p-2 pt-0 videoScreen">
              <div className="w-100 items-center videoScreenElem">
                <div className="w-3 h-3 rounded bg-danger"></div>
                <div className="w-3 h-3 rounded bg-warning ml-2"></div>
                <div className="w-3 h-3 rounded bg-success ml-2"></div>
              </div>
              <div className="w-100 overflow-hidden">
                <video
                  className="rounded-lg"
                  src={`/assets/videos/attendance.mov`}
                  autoPlay
                  muted
                  playsInline
                  loop
                  style={{
                    width: "-webkit-fill-available",
                  }}
                ></video>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="slice slice-lg bg-ex-light">
        <div className="container">
          <div className="row row-grid justify-content-around align-items-center">
            <div className="col-lg-6 order-lg-2 ml-lg-auto pl-lg-6">
              <h5 className="h2 mt-4 text-capitalize">{`${
                t("Products")[1]?.heading[1]
              }`}</h5>
              <p className="lead lh-190 my-4 text-muted">
                {t("Products")[1]?.tagline}
              </p>
              <ul className="list-unstyled">
                {t("Products")[1]?.content[0]?.map((product, key) => {
                  return (
                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div>
                          <div className="icon icon-shape icon-primary icon-sm rounded-circle mr-3">
                            <Icon.Check />
                          </div>
                        </div>
                        <div>
                          <span className="h6 mb-0">{product.heading}</span>
                        </div>
                      </div>
                    </li>
                  );
                })}
                <li className="py-2">
                  <div className="d-flex align-items-center">
                    <div className="text-center text-md-left mt-4">
                      <a
                        className="btn bg-secondary text-dark btn-icon mr-2"
                        href="/dashboard"
                      >
                        <span className="btn-inner--text">
                          {t("landing").button[1]}
                        </span>
                        <span className="btn-inner--icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <line x1="5" y1="12" x2="19" y2="12"></line>
                            <polyline points="12 5 19 12 12 19"></polyline>
                          </svg>{" "}
                        </span>
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-lg-6 order-lg-1 shadow-lg-xl rounded-xl overflow-hidden bg-white box-content p-2 pt-0 videoScreen">
              <div className="w-100 items-center videoScreenElem">
                <div className="w-3 h-3 rounded bg-danger"></div>
                <div className="w-3 h-3 rounded bg-warning ml-2"></div>
                <div className="w-3 h-3 rounded bg-success ml-2"></div>
              </div>
              <div className="w-100 overflow-hidden">
                <video
                  className="rounded-lg"
                  src={`/assets/videos/dashboard.mov`}
                  autoPlay
                  muted
                  playsInline
                  loop
                  style={{
                    width: "-webkit-fill-available",
                  }}
                ></video>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="slice slice-lg bg-ex-light">
        <div className="container">
          <div className="row row-grid justify-content-around align-items-center">
            <div className="col-lg-6 order-lg-1 ml-lg-auto pr-lg-6">
              <h5 className="h2 mt-4">{t("landing").whiteboard[0]}</h5>
              <p className="lead lh-190 my-4 text-muted">
                {t("landing").whiteboard[1]}
              </p>
              <ul className="list-unstyled">
                <li className="py-2">
                  <div className="d-flex align-items-center">
                    <div>
                      <div className="icon icon-shape icon-primary icon-sm rounded-circle mr-3">
                        <Icon.Check />
                      </div>
                    </div>
                    <div>
                      <span className="h6 mb-0">
                        {t("landing").whiteboard[2][0]}
                      </span>
                    </div>
                  </div>
                </li>
                <li className="py-2">
                  <div className="d-flex align-items-center">
                    <div>
                      <div className="icon icon-shape icon-primary icon-sm rounded-circle mr-3">
                        <Icon.Check />
                      </div>
                    </div>
                    <div>
                      <span className="h6 mb-0">
                        {t("landing").whiteboard[2][1]}
                      </span>
                    </div>
                  </div>
                </li>
                <li className="py-2">
                  <div className="d-flex align-items-center">
                    <div>
                      <div className="icon icon-shape icon-primary icon-sm rounded-circle mr-3">
                        <Icon.Check />
                      </div>
                    </div>
                    <div>
                      <span className="h6 mb-0">
                        {t("landing").whiteboard[2][2]}
                      </span>
                    </div>
                  </div>
                </li>
                <li className="py-2">
                  <div className="d-flex align-items-center">
                    <div>
                      <div className="icon icon-shape icon-primary icon-sm rounded-circle mr-3">
                        <Icon.Check />
                      </div>
                    </div>
                    <div>
                      <span className="h6 mb-0">
                        {t("landing").whiteboard[2][3]}
                      </span>
                    </div>
                  </div>
                </li>
                <li className="py-2">
                  <div className="d-flex align-items-center">
                    <div className="text-center text-md-left mt-4">
                      <a
                        className="btn bg-secondary text-dark btn-icon mr-2"
                        href="/whiteboard"
                      >
                        <span className="btn-inner--text">
                          {t("landing").whiteboard[3]}
                        </span>
                        <span className="btn-inner--icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <line x1="5" y1="12" x2="19" y2="12"></line>
                            <polyline points="12 5 19 12 12 19"></polyline>
                          </svg>{" "}
                        </span>
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-lg-6 order-lg-2 shadow-lg-xl rounded-xl overflow-hidden bg-white box-content p-2 pt-0 videoScreen">
              <div className="w-100 items-center videoScreenElem">
                <div className="w-3 h-3 rounded bg-danger"></div>
                <div className="w-3 h-3 rounded bg-warning ml-2"></div>
                <div className="w-3 h-3 rounded bg-success ml-2"></div>
              </div>
              <div className="w-100 overflow-hidden">
                <video
                  className="rounded-lg"
                  src={`https://${config.STORAGE}.blob.core.windows.net/react-images/ACV_ParabolaClear.mp4`}
                  autoPlay
                  muted
                  playsInline
                  loop
                  style={{
                    width: "-webkit-fill-available",
                  }}
                ></video>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mb-2" id="Resources" />
      <section className="slice slice-lg">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-sm-6">
              <div className="card hover-shadow-lg hover-translate-y-n3 mb-lg-0">
                <div className="card-body text-center py-5 h-100">
                  <div className="mb-4 mx-auto">
                    <img
                      alt="ACV - Tangible IT"
                      src={`https://${config.STORAGE}.blob.core.windows.net/react-images/leadership.png`}
                      className="pb-3"
                    />
                    <h2>{t("landing").resource[0][0]}</h2>
                  </div>
                  <p className="px-2 mb-4 f16">{t("landing").resource[0][1]}</p>
                  <Link
                    to="leadership"
                    className="link link-underline-dark f16"
                  >
                    {t("landing").resource[0][2]}
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="card hover-shadow-lg hover-translate-y-n3 mb-sm-0">
                <div className="card-body text-center py-5 h-100">
                  <div className="mb-4 mx-auto">
                    <img
                      alt="ACV - Tangible IT"
                      src={`https://${config.STORAGE}.blob.core.windows.net/react-images/educator.png`}
                      className="pb-3"
                    />
                    <h2>{t("landing").resource[1][0]}</h2>
                  </div>
                  <p className="px-2 mb-4 f16">{t("landing").resource[1][1]}</p>
                  <Link to="/educator" className="link link-underline-dark f16">
                    {t("landing").resource[1][2]}
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="card hover-shadow-lg hover-translate-y-n3 mb-sm-0">
                <div className="card-body text-center py-5 h-100">
                  <div className="mb-4 mx-auto">
                    <img
                      alt="ACV - Tangible IT"
                      src={`https://${config.STORAGE}.blob.core.windows.net/react-images/student.png`}
                      className="pb-3"
                    />
                    <h2>{t("landing").resource[2][0]}</h2>
                  </div>
                  <p className="px-2 mb-4 f16">{t("landing").resource[2][1]}</p>
                  <Link to="/students" className="link link-underline-dark f16">
                    {t("landing").resource[2][2]}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Cta />
      <Footer setMsgData={(data) => setMsgData(data)} />
    </div>
  );
};

export default Landing;
