import { Loader } from "@fluentui/react-northstar";
import logo from "assets/img/icon.png";
import axios from "axios";
import config from "config";
import { UserContext } from "context/UserContext";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import * as Icon from "react-feather";
import { Link } from "react-router-dom";
import { ModalProfile } from "../Modals";
import PlanHistory from "../PlanHistory";
import School from "../School";
import "./dashboard.css";

const OverView = (props) => {
  const { user, accessToken, nonSubscriber, isTrial } = useContext(UserContext);
  const [teachers, setTeachers] = useState([]);
  const [students, setStudents] = useState([]);
  const [lessons, setLessons] = useState([]);
  const [transaction, setTransaction] = useState([]);
  const [expirePlan, setExpirePlan] = useState(false);
  const [error, setError] = useState(false);
  const [teacherLoader, setTeacherLoader] = useState(false);
  const [studentLoader, setStudentLoader] = useState(false);

  useEffect(() => {
    if (user) getReferTransaction();
    if (user?.dbData?.dayRemaining < 0) {
      setExpirePlan(true);
    }
    setTeacherLoader(true);
    setStudentLoader(true);
    if (user && user?.slug && !nonSubscriber) {
      axios
        .post(
          `${config.ENDPOINT}/api/teacher/get-teachers${user?.slug}`,
          {},
          {
            headers: {
              Authorization: "Bearer " + accessToken,
            },
          }
        )
        .then((res) => {
          setTeacherLoader(false);
          setTeachers(res.data.result);
        })
        .catch((err) => {
          console.log(err);
          setTeacherLoader(false);
          setError(true);
        });
      axios
        .get(`${config.ENDPOINT}/api/student/get-students${user?.slug}`, {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        })
        .then((res) => {
          setStudentLoader(false);
          setStudents(res.data);
        })
        .catch((err) => {
          setStudentLoader(false);
          console.log(err);
        });
      axios
        .get(`${config.ENDPOINT}/api/lession/get-lessions${user?.slug}`, {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        })
        .then((res) => {
          setLessons(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setTeacherLoader(false);
      setStudentLoader(false);
    }
  }, [user, accessToken, nonSubscriber]);

  const getReferTransaction = () => {
    if (!user && nonSubscriber) return;
    axios
      .post(
        `${config.ENDPOINT}/api/refer/getReferTransaction`,
        {
          email: user?.mail,
        },
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      )
      .then((res) => {
        setTransaction(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const downloadInvoice = () => {
    if (user && user?.slug && !nonSubscriber) {
      axios
        .post(`${config.ENDPOINT}/api/payment/download-invoice`, {
          email: user?.username ? user.username : user?.mail,
        })
        .then((res) => window.open(res.data.result[0].pdf, "_blank"))
        .catch((err) => {
          console.log(err.message);
        });
    }
  };
  return (
    <>
      <ModalProfile {...props} transaction={transaction} />
      <section className="slice pb-5 mt-5 bg-section-fade">
        <div className="container">
          <div className="row mx-n2">
            <div className="col-lg-2 col-sm-3 px-2">
              <div className="card hover-shadow-lg mb-3">
                <a href={config.ACV_URL} target="_blank">
                  <div className="card-body text-center py-4 px-0">
                    <div className="mb-3 mt-2">
                      <div className="icon icon-shape icon-lg bg-danger shadow-danger text-white overflow-hidden">
                        <img className="overflow-auto" src={logo} />
                      </div>
                    </div>
                    <h5 className="h5 font-weight-bolder mb-0">Portal</h5>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-2 col-sm-3 px-2">
              <div className="card hover-shadow-lg mb-3">
                <a
                  href={`${config.ACV_URL}/dashboard/report-by-subject`}
                  target="_blank"
                >
                  <div className="card-body text-center py-4 px-0">
                    <div className="mb-3 mt-2">
                      <div className="icon icon-shape icon-lg bg-primary shadow-primary text-white overflow-hidden">
                        <Icon.BarChart2 />
                      </div>
                    </div>
                    <h5 className="h5 font-weight-bolder mb-0">Dashboard</h5>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-2 col-sm-3 px-2">
              <div className="card hover-shadow-lg mb-3">
                <a href={`${config.ACV_URL}/whiteboard`} target="_blank">
                  <div className="card-body text-center py-4 px-0">
                    <div className="mb-3 mt-2">
                      <div className="icon icon-shape icon-lg bg-warning shadow-warning text-white overflow-hidden">
                        <Icon.PenTool />
                      </div>
                    </div>
                    <h5 className="h5 font-weight-bolder mb-0">Whiteboard</h5>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-2 col-sm-3 px-2">
              <div className="card hover-shadow-lg mb-3">
                <a href={`${config.ACV_URL}/assignments`} target="_blank">
                  <div className="card-body text-center py-4 px-0">
                    <div className="mb-3 mt-2">
                      <div className="icon icon-shape icon-lg bg-success shadow-success text-white overflow-hidden">
                        <Icon.CheckCircle />
                      </div>
                    </div>
                    <h5 className="h5 font-weight-bolder mb-0">Assignment</h5>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-2 col-sm-3 px-2">
              <div className="card hover-shadow-lg mb-3">
                <a href={`${config.ACV_URL}/events/my-events`} target="_blank">
                  <div className="card-body text-center py-4 px-0">
                    <div className="mb-3 mt-2">
                      <div className="icon icon-shape icon-lg bg-info shadow-info text-white overflow-hidden">
                        <Icon.Calendar />
                      </div>
                    </div>
                    <h5 className="h5 font-weight-bolder mb-0">Events</h5>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-2 col-sm-3 px-2">
              <div className="card hover-shadow-lg mb-3">
                <a href={`${config.ACV_URL}/settings`} target="_blank">
                  <div className="card-body text-center py-4 px-0">
                    <div className="mb-3 mt-2">
                      <div className="icon icon-shape icon-lg bg-dark shadow-dark text-white overflow-hidden">
                        <Icon.Settings />
                      </div>
                    </div>
                    <h5 className="h5 font-weight-bolder mb-0">Settings</h5>
                  </div>
                </a>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-8 pr-lg-0">
              <div className="card shadow hover-shadow-lg mb-3">
                {isTrial && (
                  <div className="bagde-flag-wrap">
                    <div className="bagde-flag">Trial</div>
                  </div>
                )}
                <div className="card-body">
                  <div className="row row-grid align-items-center">
                    <div className="col-md-7 col-sm-12">
                      <div className="media align-items-center">
                        <span className="avatar bg-info text-white rounded-circle mr-3">
                          <Icon.ShoppingBag size={"22"} />
                        </span>
                        <div className="media-body">
                          <h5 className="mb-0">
                            {user?.dbData?.Subscription} Account
                          </h5>
                          <p className="text-muted lh-150 text-sm mb-0">
                            {expirePlan
                              ? "Your plan has been expired"
                              : "Your plan will expire on " +
                                moment(user?.dbData?.ExpirationDate).format(
                                  "MMMM D, YYYY"
                                )}
                            .
                          </p>
                        </div>
                      </div>
                    </div>
                    {!isTrial && (
                      <div className="col-auto flex-md-fill mt-2 mt-sm-3 mt-lg-0 text-sm-right">
                        <a
                          className="btn btn-sm btn-neutral rounded-pill"
                          onClick={() => downloadInvoice()}
                        >
                          Download
                        </a>

                        {user?.slug && !nonSubscriber && (
                          <PlanHistory user={user}>
                            <a
                              className="btn btn-sm btn-neutral rounded-pill"
                              data-toggle="modal"
                              data-target="#planHistory"
                            >
                              History
                            </a>
                          </PlanHistory>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <div className="row align-items-center">
                    <div className="col">
                      <span className="h6">School Information</span>
                    </div>
                  </div>
                </div>
                <div className="card-body pt-0">
                  <div className="row mx-md-n2">
                    <School />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              {user?.dbData?.dayRemaining !== undefined && (
                <div className="card hover-shadow-lg mb-3">
                  <div className="card-header">
                    <div className="row align-items-center">
                      <div className="col">
                        <span className="h6">Remaining</span>
                      </div>
                      <div className="col-auto ml-auto text-right">
                        <span className="h6 text-sm text-muted">
                          {user?.dbData?.dayRemaining < 0
                            ? 0
                            : user?.dbData?.dayRemaining}{" "}
                          Days
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div>
                      <div
                        className="progress-circle progress-lg mx-auto"
                        id="progress-performance-1"
                        data-trailwidth="4"
                        data-text={
                          user?.dbData?.dayRemaining < 0
                            ? 0
                            : Math.round(
                                100 - user?.dbData?.dayRemaining / 3.65
                              ) + "%"
                        }
                        data-textclassname="h3"
                        data-shape="circle"
                        data-color="primary"
                        style={{ position: "relative" }}
                      >
                        <svg viewBox="0 0 100 100" style={{ display: "block" }}>
                          <path
                            d="M 50,50 m 0,-46.5 a 46.5,46.5 0 1 1 0,93 a 46.5,46.5 0 1 1 0,-93"
                            stroke="#eee"
                            strokeWidth="4"
                            fillOpacity="0"
                          ></path>
                          <path
                            d="M 50,50 m 0,-46.5 a 46.5,46.5 0 1 1 0,93 a 46.5,46.5 0 1 1 0,-93"
                            stroke="#008aff"
                            strokeWidth="7"
                            fillOpacity="0"
                            style={{
                              strokeDasharray: "292, 292",
                              // strokeDashoffset: "81.8366",
                              strokeDashoffset:
                                (user?.dbData?.dayRemaining * 292) / 365 ||
                                81.8366,
                            }}
                          ></path>
                        </svg>
                        <div
                          className="h3"
                          style={{
                            position: "absolute",
                            left: "50%",
                            top: "50%",
                            padding: "0px",
                            margin: "0px",
                            transform: "translate(-50%, -50%)",
                            color: "rgb(0, 138, 255)",
                          }}
                        >
                          {user?.dbData?.dayRemaining < 0
                            ? 0
                            : Math.round(
                                100 - user?.dbData?.dayRemaining / 3.65
                              ) + "%"}
                        </div>
                      </div>
                    </div>
                    <hr className="my-4" />
                    <div className="d-flex align-items-center mb-2">
                      <div>
                        <span className="icon icon-shape icon-sm bg-soft-success text-success">
                          <Icon.Clock />
                        </span>
                      </div>
                      <div className="pl-2">
                        <span className="text-success text-sm font-weight-bold">
                          {user?.dbData?.dayRemaining < 0
                            ? 0
                            : user?.dbData?.dayRemaining}
                        </span>
                      </div>
                    </div>
                    <p className="mb-0 text-sm text-muted">
                      Your Plan validity is of{" "}
                      {user?.dbData?.dayRemaining < 0
                        ? 0
                        : user?.dbData?.dayRemaining}{" "}
                      days.
                    </p>
                  </div>
                </div>
              )}
              <div className="card hover-shadow-lg mb-3">
                <div className="card-body">
                  <div className="d-flex">
                    <div className="col-4 text-center">
                      <p className="h5 font-weight-bolder text-warning mb-0 cur-pointer">
                        {teachers && teachers.length}
                      </p>{" "}
                      <span className="d-block text-sm text-nowrap">
                        Teachers
                      </span>
                    </div>
                    <div className="col-4 text-center">
                      <p className="h5 font-weight-bolder text-warning mb-0 cur-pointer">
                        {students && students.length}
                      </p>{" "}
                      <span className="d-block text-sm text-nowrap">
                        Students
                      </span>
                    </div>
                    <div className="col-4 text-center">
                      <p className="h5 font-weight-bolder text-warning mb-0 cur-pointer">
                        {lessons && lessons.length}
                      </p>{" "}
                      <span className="d-block text-sm text-nowrap">
                        Lessons
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              {user?.dbData?.Subscription !== "Gold" && (
                <div className="card hover-shadow-lg">
                  <div className="card-body text-center px-5">
                    <div className="pb-4 px-5 px-md-9 px-lg-5">
                      <img
                        src="https://preview.webpixels.io/quick-website-ui-kit/assets/img/svg/illustrations/illustration-15.svg"
                        className="img-fluid img-center"
                        alt="Illustration"
                      />
                    </div>
                    <h5 className="h5 mb-3">Upgrade to Pro</h5>
                    <p className="text-muted">
                      One year support, montlhy updates for up to 5 team
                      members.
                    </p>
                    <Link to="/prices" className="btn btn-sm btn-primary">
                      Upgrade
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div>
            {teachers && (
              <div>
                <div className="row align-items-center mb-4">
                  <div className="col">
                    <h5 className="h4 mb-0">Your Teachers</h5>
                  </div>
                  <div className="col-auto">
                    <div className="dropdown">
                      <button
                        type="button"
                        className="btn btn-sm btn-blue rounded-circle"
                        onClick={() =>
                          window.open(
                            `${config.ACV_URL}/settings/manage/teachers`,
                            "_blank"
                          )
                        }
                      >
                        <span className="btn-inner--icon pr-2">
                          <Icon.Users size={18} />
                        </span>
                        Explore all
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row mx-n2">
                  {teacherLoader ? (
                    <Loader size="medium" />
                  ) : (
                    Array.isArray(teachers) &&
                    teachers?.length > 0 &&
                    teachers?.slice(0, 12).map((teacher, key) => {
                      return (
                        <div
                          className="col-md-3 col-sm-6 px-2"
                          key={"teacher" + key}
                        >
                          <div className="card hover-shadow-lg">
                            <div className="card-body text-center">
                              <div className="avatar-parent-child">
                                {teacher.UrlPhoto ? (
                                  <img
                                    alt="Image placeholder"
                                    src={teacher.UrlPhoto + user.SASToken}
                                    className="avatar rounded-circle avatar-lg shadow-light"
                                  />
                                ) : (
                                  <div className="icon icon-shape icon-lg bg-light shadow-light text-white overflow-hidden avatar rounded-circle avatar-lg">
                                    <Icon.User color="black" />
                                  </div>
                                )}
                                <span
                                  className={
                                    teacher.isVirtual
                                      ? "avatar-child avatar-badge bg-warning"
                                      : "avatar-child avatar-badge bg-success"
                                  }
                                ></span>
                              </div>
                              <span
                                className="d-block h6 mt-4 mb-0"
                                style={{ color: "#0061b3" }}
                              >
                                {teacher.TeacherName
                                  ? teacher.TeacherName
                                  : "No Name"}
                              </span>{" "}
                              <span className="d-block text-sm text-muted mb-3">
                                {teacher.TeacherEmailID}
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  )}
                </div>
              </div>
            )}
            {students && (
              <div>
                <div className="row align-items-center mb-4">
                  <div className="col">
                    <h5 className="h4 mb-0">Your Students</h5>
                  </div>
                  <div className="col-auto">
                    <div className="dropdown">
                      <button
                        type="button"
                        className="btn btn-sm btn-blue rounded-circle"
                        onClick={() =>
                          window.open(
                            `${config.ACV_URL}/settings/manage/students`,
                            "_blank"
                          )
                        }
                      >
                        <span className="btn-inner--icon pr-2">
                          <Icon.Users size={18} />
                        </span>
                        Explore all
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row mx-n2">
                  {studentLoader ? (
                    <Loader size="medium" />
                  ) : (
                    students?.slice(0, 12).map((student, key) => {
                      return (
                        <div
                          className="col-md-3 col-sm-6 px-2"
                          key={"student" + key}
                        >
                          <div className="card hover-shadow-lg">
                            <div className="card-body text-center">
                              <div className="avatar-parent-child">
                                {student?.studPhoto?.Foto ? (
                                  <img
                                    alt="Image placeholder"
                                    src={student.studPhoto.Foto + user.SASToken}
                                    className="avatar rounded-circle avatar-lg shadow-light"
                                  />
                                ) : (
                                  <div className="icon icon-shape icon-lg bg-light shadow-light text-white overflow-hidden avatar rounded-circle avatar-lg">
                                    <Icon.User color="black" />
                                  </div>
                                )}
                                <span
                                  className={
                                    student.isVirtual
                                      ? "avatar-child avatar-badge bg-warning"
                                      : "avatar-child avatar-badge bg-success"
                                  }
                                ></span>
                              </div>
                              <span
                                className="d-block h6 mt-4 mb-0"
                                style={{ color: "#0061b3" }}
                              >
                                {student.StudentName}
                              </span>{" "}
                              <span className="d-block text-sm text-muted mb-3">
                                {student.StudentEmailID}
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default OverView;
