import axios from "axios";
import config from "config";
import { useState } from "react";

export const useVerifyCouponHandlers = ({ onAfterVerified }) => {
  const [loader, setLoader] = useState(false);
  const url = new URL(window.location.href);
  const [coupon] = useState(url.searchParams.get("coupon"));
  const [msgData, setMsgData] = useState(null);

  const verifyCoupon = () => {
    setLoader(true);
    setMsgData({
      message: "Verifying your coupon...",
      type: "Warning",
    });
    localStorage.setItem(
      "toastMessage",
      JSON.stringify({
        message: "Verifying your coupon...",
        type: "Warning",
      })
    );
    coupon &&
      axios
        .post(`${config.ENDPOINT}/api/admin/verify-coupon-code`, {
          Coupon: coupon,
          language: localStorage.getItem("i18nextLng"),
        })
        .then((res) => {
          localStorage.setItem(
            "toastMessage",
            JSON.stringify({
              message: res.data.message,
              type: res.data.status,
            })
          );
          setMsgData({
            message: res.data.message,
            type: res.data.status,
          });
          setLoader(false);
          onAfterVerified?.();
          setTimeout(() => {
            setMsgData(null);
            localStorage.removeItem("toastMessage");
          }, 5000);
          // document.getElementById("close-modal").click();
        })
        .catch((err) => {
          console.log(err);
          // Handle error by storing message in localStorage
          localStorage.setItem(
            "toastMessage",
            JSON.stringify({
              message: err.response?.data?.message || "Error occurred",
              type: "Error",
            })
          );
          setMsgData({ message: err.response?.data?.message, type: "Error" });
          setLoader(false);
          onAfterVerified?.();
          setTimeout(() => {
            setMsgData(null);
          }, 5000);
        });
  };

  return {
    verifyCoupon,
    coupon,
    isLoading: loader,
  };
};
