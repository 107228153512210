import {
  Accordion,
  Button,
  LeaveIcon,
  Loader,
  TeamsMonochromeIcon,
  Dropdown,
} from "@fluentui/react-northstar";
import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";

import { Dialog } from "@fluentui/react-northstar";

import "./Customer.css";
import * as Icon from "react-feather";
import { OnboardingContext } from "context/OnboardingContext";
import { Link, useHistory } from "react-router-dom";
import config from "config";

import { GoogleLogin, GoogleLogout } from "react-google-login";
import { CustomerContext } from "./context/customer.context";
import { UserContext } from "context/UserContext";

const Customer = (props) => {
  const { customer, setCustomer } = useContext(CustomerContext);
  const { t } = useTranslation();

  const { user, setUser } = useContext(UserContext);

  const schoolInfo = props.schoolInfo;
  const noOfTeachers = props.noOfTeachers;
  const [IsLoader, setIsLoader] = useState(false);
  const [SchoolLogo, setSchoolLogo] = useState(null);
  const [SchoolImage, setSchoolImage] = useState(null);
  const [isChecked, setIsChecked] = useState(true);
  const [isError, setIsError] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [isSiningIn, setIsSiningIn] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [login, setLogin] = useState(false);
  const [google, setGoogle] = useState(false);
  const [provider, setProvider] = useState("microsoft");
  const [accessToken, setAccessToken] = useState();
  const [googleEmail, setGoogleEmail] = useState(null);
  const [isCreatingSession, setIsCreatingSession] = useState(false);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState({});
  const [currency, setCurrency] = useState("MXN");
  const [activeAccordionIndex, setActiveAccordionIndex] = useState([0]);
  const [isCFDIRequired, setIsCFDIRequired] = useState(false);
  const [fiscalData, setFiscalData] = useState({
    fiscalDataType: "uploadFile",
    fiscalDataFile: "",
    RFC: "",
    businessName: "",
    address: "",
    postalCode: "",
    billingemail: "",
  });
  const [productData, setProductData] = useState({
    productId: "",
    priceId: "",
  });

  const url = config.ENDPOINT;
  const context = useContext(OnboardingContext);
  const history = useHistory();

  useEffect(() => {
    const { priceId, planType } = context.onboardingState;
    setCustomer({
      ...customer,
      subscripation_type: planType,
      priceId: priceId,
    });

    // if (props?.userData?.userName) {
    //   setLogin(true);
    // }
    const isSignIn = Boolean(localStorage.getItem("isSignIn"));
    if (isSignIn) {
      setIsSiningIn(true);
    }

    fetch("https://restcountries.com/v3.1/all")
      .then((response) => response.json())
      .then((data) => {
        const countryNames = data.map((country) => country.name.common);
        setCountries(countryNames);
        setSelectedCountry("United States");
      });
    return () => {
      setSelectedCountry("United States");
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (selectedCountry === "Mexico") {
        try {
          const response = await axios.get(
            `${url}/api/payment/get-mexico-price-config`
          );
          const { prices } = response.data;
          const priceInfo = prices.find(
            (price) => price.metadata.display_name === props.priceData.plan
          );
          setProductData({
            productId: priceInfo.product.id,
            priceId: priceInfo.id,
          });
        } catch (error) {
          console.log(error);
        }
      }
    };

    fetchData();
  }, [selectedCountry]);

  useEffect(() => {
    checkLogined();
  }, [schoolInfo]);

  const checkLogined = () => {
    if (schoolInfo) {
      setDisabled(true);
      setCustomer((prevCustomer) => ({
        ...prevCustomer,
        email: schoolInfo?.ContactEmail || "",
        name: props?.userData?.userName || "",
        school: schoolInfo?.School || "",
        phone: schoolInfo?.ContactPhone || "",
        address: schoolInfo?.SchoolAddress || "",
        job: "",
      }));
    }
  };

  const createSubscription = async (e) => {
    e.preventDefault();
    setIsCreatingSession(true);
    const refer_token = window.localStorage.getItem("refertoken") || "";
    const language =
      window.localStorage
        .getItem("i18nextLng")
        ?.substring(0, 2)
        ?.toLowerCase() || "en";
    try {
      if (customer?.email && user?.role === "SuperAdmin") {
        const postData = {
          priceID:
            selectedCountry === "Mexico"
              ? productData.priceId
              : props.priceData.priceId,
          email: google ? googleEmail : customer?.email,
          price: props.priceData.price,
          productId:
            selectedCountry === "Mexico"
              ? productData.productId
              : props.priceData.productId,
          currency: selectedCountry === "Mexico" ? "MXN" : "USD",
          paymentType: "subscription",
        };

        await axios
          .post(`${url}/api/payment/update-subscriptions`, postData)
          .then((res) => {
            setIsCreatingSession(false);
            window.location.href = res.data.result.session.url;
          })
          .catch((err) => {
            setIsCreatingSession(false);
            const { status } = err.response;
            if (status === 409) {
              setIsError(true);
            }
          });
      } else {
        const postData = {
          paymentType: "subscription",
          refer_token,
          price: props.priceData.price,
          productId:
            selectedCountry === "Mexico"
              ? productData.productId
              : props.priceData.productId,
          school: customer.school,
          phone: customer.phone,
          address: customer.address,
          job: customer.job,
          ContactEmail: google ? googleEmail : customer.email,
          subscripation_type: props.priceData.plan,
          teachers: noOfTeachers,
          language,
          provider,
          currency: selectedCountry === "Mexico" ? "MXN" : "USD",
        };

        const data = await axios
          .post(`${url}/api/payment/create-checkout-session`, postData, {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + accessToken,
            },
          })
          .then((res) => {
            window.location.href = res.data.session.url;
            setIsCreatingSession(false);
          })
          .catch((err) => {
            setIsCreatingSession(false);
            const { status } = err.response;
            if (status === 409) {
              setIsError(true);
            }
          });
        if (selectedCountry === "Mexico") {
          const fiscalDataForm = new FormData();
          fiscalDataForm.append("csfFile", fiscalData.fiscalDataFile);
          fiscalDataForm.append("rfc", fiscalData.RFC);
          fiscalDataForm.append("businessName", fiscalData.businessName);
          fiscalDataForm.append("address", fiscalData.address);
          fiscalDataForm.append("postalCode", fiscalData.postalCode);
          fiscalDataForm.append("billingemail", fiscalData.billingemail);
          fiscalDataForm.append("currency", currency);
          fiscalDataForm.append("isCFDIRequired", isCFDIRequired);
          fiscalDataForm.append("fiscalDataType", fiscalData.fiscalDataType);
          fiscalDataForm.append("country", selectedCountry);
          fiscalDataForm.append("email", google ? googleEmail : customer.email);

          await axios
            .post(`${url}/api/payment/create-mexican-customer`, fiscalDataForm)
            .then((res) => {
              return;
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          onCreateCustomerHandler(e);
        }
      }
      window.localStorage.removeItem("RequestDemo");
      window.localStorage.removeItem("registerModal");
      window.localStorage.removeItem("el");
      window.localStorage.removeItem("priceData");
    } catch (err) {
      setIsCreatingSession(false);
      console.log(err);
    }
  };

  const terms_changed = () => {
    setIsChecked(!isChecked);
  };

  const onCreateCustomerHandler = async (e) => {
    setIsLoader(true);
    var constomerData = { ...customer };
    constomerData = {
      ...constomerData,
      SchoolImage,
      SchoolLogo,
    };

    await axios
      .post(`${url}/api/payment/create-customer`, customer)
      .then((res) => {
        setIsLoader(false);
        if (res.data.status !== "Error") {
          setCustomer({
            customerId: res.data.customer.id,
          });
          createSubscription(res.data.customer.id);
        }
      })
      .catch((err) => {
        setIsLoader(false);
        console.log(err);
      });
  };

  const onInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "SchoolImage") {
      setSchoolImage(e.target.files[0]);
    }
    if (name === "SchoolLogo") {
      setSchoolLogo(e.target.files[0]);
    }
    setCustomer((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const checkUserExits = () => {
    if (customer?.email !== null) {
      axios
        .post(`${url}/api/payment/check-user-exits`, {
          Email: customer.email,
        })
        .then((res) => {
          setIsError(false);
        })
        .catch((err) => {
          const { status } = err.response;
          if (status === 409) {
            setIsError(true);
          }
        });
    }
  };

  const existingCustomerLogin = () => {
    props.login("microsoft");
    localStorage.setItem("IsUserCreateAccount", true);
    window.localStorage.setItem("el", true);
  };

  const onFailure = (err) => {
    console.log(err);
  };

  const onSuccess = (res) => {
    setProvider("google");
    setGoogle(true);
    setGoogleEmail(res.profileObj.email);
    setCustomer((prevState) => ({
      ...prevState,
      name: res.profileObj.name,
      email: res.profileObj.email,
    }));
    setUser({
      ...user,
      email: res.profileObj.email,
      name: res.profileObj.name,
    });
    setAccessToken(res.tokenObj.id_token);
  };

  const logout = (response) => {
    setGoogle(false);
    setCustomer((prevState) => ({
      ...prevState,
      name: "",
      email: "",
    }));
  };

  const handleLogoutFailure = (err) => {
    console.log(err);
  };

  return (
    <>
      <section className="Landing Register">
        <Dialog
          open={dialog}
          onConfirm={() => (window.location.href = "/prices")}
          onCancel={() => {
            setDialog(false);
            window.localStorage.removeItem("registerModal");
            window.localStorage.removeItem("el");
            window.localStorage.removeItem("priceData");
          }}
          confirmButton="OK"
          cancelButton="Cancel"
          content={
            'You haven\'t choose your plan, If you click "OK" you would be redirected to pricing.'
          }
          header="Your ACV Plan"
        />
        <div
          className="px-4 d-flex flex-column"
          style={{ overflow: "scroll", marginBottom: "2rem" }}
        >
          <div className="align-items-center justify-content-center min-vh-80">
            <div className="">
              <div>
                <div className="mb-2 text-center">
                  <h6 className="h3 mb-2 pt-4">
                    {schoolInfo
                      ? "Acv Customer"
                      : isSiningIn
                      ? t("landing").registration[0]
                      : "Login"}
                  </h6>
                  <p className="text-muted mb-0">
                    {t("landing").registration[1][0]}
                    <Icon.Heart
                      color="#d33139"
                      fill="#d33139"
                      size="16"
                      style={{ marginBottom: "2px" }}
                    />
                    {t("landing").registration[1][1]}
                  </p>
                </div>

                <span className="clearfix"></span>
                {isSiningIn ? (
                  <form
                    onSubmit={(e) => {
                      createSubscription(e);
                    }}
                    method="POST"
                    className="registration-form"
                  >
                    <div
                      className="form-group"
                      // style={{ margin: "0 0.5rem 1.3rem" }}
                    >
                      <div className="input-group input-group-merge">
                        <input
                          type="text"
                          className="form-control form-control-prepend"
                          id="input-name"
                          placeholder={t("landing").registration[2]}
                          name="name"
                          onChange={(e) => onInputChange(e)}
                          value={customer?.name}
                          required
                        />
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <Icon.User size="18" />
                          </span>
                        </div>
                      </div>
                    </div>
                    <fieldset>
                      <Accordion
                        activeIndex={activeAccordionIndex}
                        onTitleClick={() =>
                          activeAccordionIndex.includes(0)
                            ? setActiveAccordionIndex([])
                            : setActiveAccordionIndex([0])
                        }
                        panels={[
                          {
                            title: "School Information",
                            key: "register-form",
                            content: (
                              <>
                                <div className="form-group">
                                  <div className="d-block text-danger">
                                    <div className="input-group input-group-merge d-flex">
                                      <input
                                        type="email"
                                        className="form-control form-control-prepend icon-input pr-3"
                                        style={{
                                          borderColor: isError ? "#d33139" : "",
                                          // borderRight: 0,
                                        }}
                                        id="input-email"
                                        placeholder={
                                          t("landing").registration[4]
                                        }
                                        name="email"
                                        onChange={(e) => onInputChange(e)}
                                        onBlur={
                                          !disabled ? checkUserExits : () => {}
                                        }
                                        value={customer?.email}
                                        disabled={true}
                                        required
                                      />

                                      <div className="input-group-prepend">
                                        <span
                                          className="input-group-text"
                                          style={{
                                            borderColor: isError
                                              ? "#d33139"
                                              : "",
                                          }}
                                        >
                                          {isError ? (
                                            <Icon.AlertCircle
                                              size="18"
                                              color="#d33139"
                                            />
                                          ) : (
                                            <Icon.Mail size="18" />
                                          )}
                                        </span>
                                      </div>
                                      {google && (
                                        <a>
                                          <GoogleLogout
                                            clientId={config.GOOGLE_CLIENT_ID}
                                            buttonText={
                                              <Icon.X
                                                color="#d33139"
                                                fill="#d33139"
                                                size="18"
                                              />
                                            }
                                            className="close-icon"
                                            onLogoutSuccess={logout}
                                            onFailure={handleLogoutFailure}
                                          />
                                        </a>
                                      )}

                                      <div className="input-group-prepend">
                                        <span
                                          className="input-group-text"
                                          style={{
                                            borderColor: isError
                                              ? "#d33139"
                                              : "",
                                            paddingRight: 0,
                                          }}
                                        >
                                          <GoogleLogin
                                            clientId={config.GOOGLE_CLIENT_ID}
                                            buttonText=""
                                            onSuccess={onSuccess}
                                            onFailure={onFailure}
                                            cookiePolicy={"single_host_origin"}
                                            className="google_btn_1 "
                                            scope="profile"
                                            redirectUri={config.APP_URI}
                                          />
                                        </span>
                                      </div>

                                      {!login && (
                                        <div className="input-group-prepend">
                                          <span
                                            className="input-group-text"
                                            style={{
                                              borderColor: isError
                                                ? "#d33139"
                                                : "",
                                              color: "#4F57BF",
                                            }}
                                          >
                                            <TeamsMonochromeIcon
                                              size="large"
                                              onClick={existingCustomerLogin}
                                              style={{ cursor: "pointer" }}
                                            />
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                    {isError && (
                                      <span className="email__error_msg">
                                        This email is already register. please
                                        try with another one!
                                      </span>
                                    )}
                                  </div>
                                </div>
                                <div className="form-group">
                                  <div className="input-group input-group-merge">
                                    <input
                                      type="text"
                                      className="form-control form-control-prepend"
                                      id="input-school"
                                      placeholder={t("landing").registration[5]}
                                      name="school"
                                      onChange={(e) => onInputChange(e)}
                                      value={customer?.school}
                                      disabled={disabled}
                                      required
                                    />
                                    <div className="input-group-prepend">
                                      <span className="input-group-text">
                                        <Icon.Users size="18" />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group">
                                  <div className="input-group input-group-merge">
                                    <input
                                      type="text"
                                      className="form-control form-control-prepend"
                                      id="input-address"
                                      placeholder={t("landing").registration[6]}
                                      name="address"
                                      onChange={(e) => onInputChange(e)}
                                      value={customer?.address}
                                      disabled={disabled}
                                      required
                                    />
                                    <div className="input-group-prepend">
                                      <span className="input-group-text">
                                        <Icon.Home size="18" />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group">
                                  <div className="input-group input-group-merge">
                                    <input
                                      type="text"
                                      className="form-control form-control-prepend"
                                      id="input-phone"
                                      placeholder={t("landing").registration[7]}
                                      name="phone"
                                      onChange={(e) => onInputChange(e)}
                                      value={customer?.phone}
                                      disabled={disabled}
                                      required
                                    />
                                    <div className="input-group-prepend">
                                      <span className="input-group-text">
                                        <Icon.Phone size="18" />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group">
                                  <div className="input-group input-group-merge">
                                    <Dropdown
                                      search
                                      items={countries}
                                      placeholder="Search country name"
                                      noResultsMessage="We couldn't find any matches."
                                      value={selectedCountry}
                                      getA11ySelectionMessage={{
                                        onAdd: (item) => {
                                          setSelectedCountry(item);
                                          if (item === "Mexico") {
                                            setActiveAccordionIndex([]);
                                          }
                                        },
                                      }}
                                      className="form-control form-control-prepend "
                                    />
                                    <div className="input-group-prepend">
                                      <span className="input-group-text">
                                        <Icon.Flag size="18" />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ),
                          },
                        ]}
                        className="mb-0"
                      />
                    </fieldset>
                    {selectedCountry === "Mexico" && (
                      <fieldset className="mt-4">
                        <Accordion
                          activeIndex={
                            activeAccordionIndex.length > 0 ? [] : [0]
                          }
                          onTitleClick={() =>
                            activeAccordionIndex.includes(0)
                              ? setActiveAccordionIndex([])
                              : setActiveAccordionIndex([0])
                          }
                          panels={[
                            {
                              title: "CFDI Information",
                              key: "cfdi-form",
                              content: (
                                <div className="overflow-auto cfdi-form">
                                  <div className="mb-3 d-flex p-0 justify-content-between mt-3 align-items-baseline">
                                    <p className="text-muted font-16">
                                      Preferred Currency to pay
                                    </p>
                                    <div className="form-check form-check-inline align-items-center">
                                      <input
                                        type="radio"
                                        id="usd"
                                        name="currency"
                                        value="USD"
                                        checked={currency === "USD"}
                                        onChange={(e) =>
                                          setCurrency(e.target.value)
                                        }
                                        class="form-check-input"
                                      />
                                      <label
                                        for="usd"
                                        className="form-check-label text-muted"
                                      >
                                        USD
                                      </label>
                                    </div>
                                    <div className="form-check form-check-inline align-items-center">
                                      <input
                                        type="radio"
                                        id="mxn"
                                        name="currency"
                                        value="MXN"
                                        checked={currency === "MXN"}
                                        onChange={(e) =>
                                          setCurrency(e.target.value)
                                        }
                                        class="form-check-input"
                                      />
                                      <label
                                        for="mxn"
                                        className="form-check-label text-muted"
                                      >
                                        MXN
                                      </label>
                                    </div>
                                  </div>
                                  <p className="text-muted p-0 font-16">
                                    Invoicing in Mexico is provided by partner
                                    Tangible IT Mexico:
                                  </p>
                                  <div className="form-check mb-3">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      value={isCFDIRequired}
                                      id="flexCheckDefault"
                                      onChange={(e) =>
                                        setIsCFDIRequired(e.target.checked)
                                      }
                                      checked={isCFDIRequired}
                                    />
                                    <label
                                      className="form-check-label text-muted font-1 "
                                      htmlFor="flexCheckDefault"
                                    >
                                      I require <b>CFDI</b> of my purchase or
                                      payments
                                    </label>
                                  </div>
                                  <div className="my-3">
                                    <p className="text-muted font-16">
                                      Share your fiscal data:
                                    </p>
                                    <div className="d-flex">
                                      <div className="form-check form-check-inline align-items-center">
                                        <input
                                          type="radio"
                                          id="csf"
                                          name="cdfi-data"
                                          value="CSF"
                                          checked={
                                            fiscalData.fiscalDataType ===
                                            "uploadFile"
                                          }
                                          onChange={(e) =>
                                            setFiscalData({
                                              ...fiscalData,
                                              fiscalDataType: "uploadFile",
                                            })
                                          }
                                          class="form-check-input"
                                        />
                                        <label
                                          for="csf"
                                          className="form-check-label text-muted "
                                        >
                                          Upload CSF
                                        </label>
                                      </div>
                                      <div className="form-check form-check-inline align-items-center">
                                        <input
                                          type="radio"
                                          id="data"
                                          name="cdfi-data"
                                          value="fiscalData"
                                          checked={
                                            fiscalData.fiscalDataType ===
                                            "fiscalData"
                                          }
                                          onChange={(e) =>
                                            setFiscalData({
                                              ...fiscalData,
                                              fiscalDataType: "fiscalData",
                                            })
                                          }
                                          class="form-check-input"
                                        />
                                        <label
                                          for="data"
                                          className="form-check-label text-muted"
                                        >
                                          Capture your fiscal data
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  {fiscalData.fiscalDataType ===
                                  "uploadFile" ? (
                                    <div className="form-group mt-3">
                                      <input
                                        type="file"
                                        className="form-control form-control-prepend"
                                        id="input-fiscal-file"
                                        name="fiscalFile"
                                        onChange={(e) =>
                                          setFiscalData({
                                            ...fiscalData,
                                            fiscalDataFile: e.target.files[0],
                                          })
                                        }
                                        required={isCFDIRequired}
                                      />
                                    </div>
                                  ) : (
                                    <>
                                      <div className="form-group mt-3">
                                        <input
                                          type="text"
                                          className="form-control form-control-prepend"
                                          id="input-rfc"
                                          placeholder="Enter RFC"
                                          name="RFC"
                                          onChange={(e) =>
                                            setFiscalData({
                                              ...fiscalData,
                                              RFC: e.target.value,
                                            })
                                          }
                                          required={isCFDIRequired}
                                        />
                                      </div>
                                      <div className="form-group mt-3">
                                        <input
                                          type="text"
                                          className="form-control form-control-prepend"
                                          id="input-business-name"
                                          placeholder="Business Name"
                                          name="businessName"
                                          onChange={(e) =>
                                            setFiscalData({
                                              ...fiscalData,
                                              businessName: e.target.value,
                                            })
                                          }
                                          required={isCFDIRequired}
                                        />
                                      </div>
                                      <div className="form-group mt-3">
                                        <input
                                          type="text"
                                          className="form-control form-control-prepend"
                                          id="input-address"
                                          placeholder="Address"
                                          name="address"
                                          onChange={(e) =>
                                            setFiscalData({
                                              ...fiscalData,
                                              address: e.target.value,
                                            })
                                          }
                                          required={isCFDIRequired}
                                        />
                                      </div>
                                      <div className="form-group mt-3">
                                        <input
                                          type="text"
                                          className="form-control form-control-prepend"
                                          id="input-postal-code"
                                          placeholder="Postal Code"
                                          name="postalCode"
                                          onChange={(e) =>
                                            setFiscalData({
                                              ...fiscalData,
                                              postalCode: e.target.value,
                                            })
                                          }
                                          required={isCFDIRequired}
                                        />
                                      </div>
                                      <div className="form-group mt-3">
                                        <input
                                          type="text"
                                          className="form-control form-control-prepend"
                                          id="input-billing-email"
                                          placeholder="Billing Email"
                                          name="billingemail"
                                          onChange={(e) =>
                                            setFiscalData({
                                              ...fiscalData,
                                              billingemail: e.target.value,
                                            })
                                          }
                                          required={isCFDIRequired}
                                        />
                                      </div>
                                    </>
                                  )}
                                </div>
                              ),
                            },
                          ]}
                          className="mb-0"
                        />
                      </fieldset>
                    )}
                    <div className="mt-4">
                      {/* <div className="custom-control custom-checkbox mb-3">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="check-terms"
                          onChange={(e) => terms_changed(e.target.checked)}
                          onClick={terms_changed}
                          checked={isChecked}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="check-terms"
                        >
                          {t("landing").registration[8][0]}
                          <Link to="/terms" style={{ color: "#d33139" }}>
                            {t("landing").registration[8][1]}
                          </Link>
                        </label>
                      </div> */}
                      <div className="form-check mb-3">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultValue=""
                          id="flexCheckDefault"
                          onChange={(e) => terms_changed(e.target.checked)}
                          checked={isChecked}
                          required
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault"
                        >
                          {t("landing").registration[8][0]}
                          <Link to="/terms" style={{ color: "#d33139" }}>
                            {t("landing").registration[8][1]}
                          </Link>
                        </label>
                      </div>
                    </div>
                    <div className="mt-4 registration-btn-wrapper d-flex">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                        onClick={() => props.setOpen(false)}
                        // onClick={localStorage.removeItem("IsUserCreateAccount")}
                      >
                        {t("landing").registration[11]}
                      </button>
                      <button
                        type="submit"
                        className="btn btn-primary d-flex align-items-center justify-content-center"
                        id="submit-btn"
                        disabled={
                          (isError === false && isChecked === true
                            ? false
                            : true) || isCreatingSession
                        }
                      >
                        {isCreatingSession
                          ? "Loading..."
                          : user?.role === "SuperAdmin" && customer?.email
                          ? t("landing").registration[12]
                          : t("landing").registration[9]}
                        {isCreatingSession && (
                          <span
                            className="spinner-border spinner-border-sm ms-3"
                            role="status"
                            aria-hidden="true"
                          />
                        )}
                      </button>
                    </div>
                  </form>
                ) : (
                  <div className="d-flex flex-column align-items-center mt-3">
                    <Button
                      icon={
                        <TeamsMonochromeIcon
                          size="larger"
                          style={{ paddingRight: "3px" }}
                        />
                      }
                      content={
                        props?.insideTeam
                          ? "Sign in with Teams"
                          : "Sign in with Microsoft"
                      }
                      className="d-flex mx-auto mb-3 rounded-2 p-4-i"
                      iconPosition="before"
                      primary
                      onClick={existingCustomerLogin}
                      disabled={google}
                    />
                    {google ? (
                      <GoogleLogout
                        clientId={config.GOOGLE_CLIENT_ID}
                        buttonText={
                          // <Icon.X color="#d33139" fill="#d33139" size="18" />
                          "Logout"
                        }
                        className="google_btn"
                        onLogoutSuccess={logout}
                        onFailure={handleLogoutFailure}
                      />
                    ) : (
                      <GoogleLogin
                        clientId={config.GOOGLE_CLIENT_ID}
                        buttonText="Sign in with Google"
                        onSuccess={(res) => {
                          props.login("google", res);
                          setGoogle(true);
                          props?.priceData?.priceId &&
                            window.localStorage.setItem(
                              "registerModal",
                              props.priceData.priceId
                            );
                        }}
                        onFailure={onFailure}
                        cookiePolicy={"single_host_origin"}
                        className="google_btn "
                        scope="profile"
                        redirectUri={config.APP_URI}
                      />
                    )}
                  </div>
                )}

                {!schoolInfo && (
                  <div
                    className="mt-4 registration-btn-wrapper text-center "
                    style={{ cursor: "pointer" }}
                  >
                    <a
                      className=""
                      id="existing-customer-link"
                      onClick={() => {
                        setIsSiningIn(!isSiningIn);
                      }}
                    >
                      {isSiningIn
                        ? t("landing").registration[10][0]
                        : "Don't have an account?"}
                    </a>
                  </div>
                )}

                <Loader
                  hidden={!IsLoader}
                  size="small"
                  style={{ paddingTop: "15px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Customer;
